import React from 'react';
import * as T from 'prop-types';
import { withLeaflet } from 'react-leaflet';
import Locate from 'leaflet.locatecontrol';

class LocateControl extends React.Component {
  componentDidMount() {
    const { options, startDirectly, leaflet } = this.props;
    const { map } = leaflet;
    const locator = new Locate(options);
    locator.addTo(map);
    if (startDirectly) {
      // Request location update and set location immediately.
      locator.start();
    }
  }

  render() {
    return null;
  }
}

LocateControl.propTypes = {
  /** Options for the Locate Control. Optional. */
  options: T.object,
  /** Triggers the locator immediately upon initialization if true. Optional. */
  startDirectly: T.bool,
  /** The instance of the leaflet map to which the control will be added. Sprinkled in by the <Map/> parent. Required. */
  leaflet: T.shape({ map: T.object }).isRequired,
};

LocateControl.defaultProps = {
  startDirectly: null,
  options: null,
};

export default withLeaflet(LocateControl);
