import { all, takeEvery, call, put, delay } from 'redux-saga/effects';
import { withAsync, messagesActions } from 'state-template';

import * as FileDownload from 'js-file-download';
import * as actions from './actions';
import * as C from './constants';
import * as requests from './requests';
import routes from '../routes';

export function* getResults(action) {
  yield put(actions.getTableLoading(true));
  const data = yield call(requests.getResultsRequest, action);
  yield put(actions.getResultsSuccess(data));
  yield put(actions.getTableLoading(false));
}

export function* getDropdowns() {
  const [
    counties,
    licenseTypes,
    licenseStatuses,
    licenseTerms,
    licenseDesignations,
    licenseAuthorities,
    licensebusinesstypes,
  ] = yield all([
    call(requests.getCountiesRequest),
    call(requests.getLicenseTypesRequest),
    call(requests.getLicenseStatusesRequest),
    call(requests.getLicenseTermsRequest),
    call(requests.getLicenseDesignationsRequest),
    call(requests.getLicenseAuthoritiesRequest),
    call(requests.getLicenseBusinessTypesRequest),
  ]);

  yield put(actions.getCountiesSuccess(counties));
  yield put(actions.getLicenseTypesSuccess(licenseTypes));
  yield put(actions.getLicenseStatusesSuccess(licenseStatuses));
  yield put(actions.getLicenseTermsSuccess(licenseTerms));
  yield put(actions.getLicenseDesignationsSuccess(licenseDesignations));
  yield put(actions.getLicenseAuthoritiesSuccess(licenseAuthorities));
  yield put(actions.getLicenseBusinessTypesSuccess(licensebusinesstypes));
}

export function* getDetails(action) {
  const data = yield call(requests.getDetailsRequest, action);
  yield put(actions.getDetailsSuccess(data));
}

export function* getRetailers(action) {
  yield put(actions.updateReatilersLoading(true));
  const data = yield call(requests.getRetailersRequest, action);
  yield put(actions.getRetailersSuccess(data));
  yield put(actions.updateReatilersLoading(false));
}

export function* postUnlicensedComplaint(action) {
  const { history, values } = action.payload;
  try {
    yield call(requests.postUnlicensedComplaint, values);
    yield delay(1000);
    yield put(actions.postUnlicensedComplaintSuccess({ submitted: true, ...values }));
    history.push(routes.unlicensedComplaintSuccess.path);
  } catch (error) {
    const errorBody = yield error.body;

    yield put(messagesActions.registerMessage({
      id: 'unlicensed-complaint',
      variant: 'standout',
      children: requests.generateRequestError(errorBody),
      delay: 10000,
    }));
  }
}

export function* getExportedResults(action) {
  const data = yield call(requests.generateSimpleRequest(action.payload));
  FileDownload(data, data.name);
}

export function* getLocationAutoSuggest(action) {
  const data = yield call(requests.getAutoSuggestLocationRequest, action.payload);
  yield put(actions.getLocationAutoSuggestSuccess(data));
}

export function* getLocation(action) {
  const data = yield call(requests.getLocationRequest, action.payload);
  yield put(actions.updateGeolocation(data));
}

export default function* saga() {
  yield all([
    takeEvery(C.GET_DROPDOWNS, withAsync(getDropdowns)),
    takeEvery(C.GET_EXPORT, withAsync(getExportedResults)),
    takeEvery(C.GET_RESULTS, withAsync(getResults)),
    takeEvery(C.GET_DETAILS, withAsync(getDetails)),
    takeEvery(C.GET_RETAILERS, withAsync(getRetailers)),
    takeEvery(C.POST_UNLICENSED_COMPLAINT, withAsync(postUnlicensedComplaint)),
    takeEvery(C.GET_LOCATON_AUTO_SUGGEST, withAsync(getLocationAutoSuggest)),
    takeEvery(C.GET_LOCATON, withAsync(getLocation)),
  ]);
}
