import React from 'react';
import { Icon, Button } from 'state-template';

import schema from 'schema';

export const columnOptions = Object.values(schema).map((column) => ({
  value: column.name,
  label: column.label,
}));

export const allColumnNames = Object.values(schema).map((x) => x.name);

export const defaultColumnNames = [
  'licenseNumber',
  'licenseType',
  'licenseStatus',
  'businessDbaName',
  'businessLegalName',
  'premiseCounty',
  'issueDate',
  'expirationDate',
  'licenseTerm',
  // 'licensingAuthority',
  'licenseDesignation',
];

const tableSchema = {
  ...schema,
  licenseNumber: {
    ...schema.licenseNumber,
    formatter: (value) => (
      <Button id={value} tabIndex={0} style={{ whiteSpace: 'nowrap' }}>
        <Icon name={'caret-down'} />
        {' '}
        {value}
      </Button>
    ),
  },
};

export const getColumn = (name) => {
  const field = tableSchema[name];

  return ({
    dataField: field.name,
    text: field.label,
    sort: true,
    // style: name === schema.licensingAuthorityId.name ? null : {
    //   minWidth: '150px',
    // },
    formatter: field.formatter,
  });
};

export const getColumns = (columnNames) => columnNames.map((name) => getColumn(name));
