
import React, { useState } from 'react';
import * as T from 'prop-types';
import * as storage from 'utils/storage';
import ListBox from './ListBox/ListBox';

import {
  defaultColumnNames,
  getColumns,
  columnOptions,
} from '../columns';

const getDefaultColumnNames = () => storage.get('savedColumnNames') || defaultColumnNames;
const getIsDefaultColumns = (columnNames) => JSON.stringify(columnNames) === JSON.stringify(defaultColumnNames);

const CustomizeResultsPanel = ({ onColumnChange }) => {
  const [columnState, setColumnState] = useState(() => {
    const columnNames = getDefaultColumnNames();

    return {
      columns: getColumns(columnNames),
      selectedColumnNames: columnNames,
      isSavedColumns: true,
      isDefaultColumns: getIsDefaultColumns(columnNames),
    };
  });

  const updateColumns = (columnNames) => {
    const columns = getColumns(columnNames);
    onColumnChange(columns);
  };

  const onCustomizeResults = (selectedColumnNames) => {
    const savedColumns = getDefaultColumnNames();

    updateColumns(selectedColumnNames);

    setColumnState({
      selectedColumnNames,
      isSavedColumns: JSON.stringify(savedColumns) === JSON.stringify(selectedColumnNames),
      isDefaultColumns: getIsDefaultColumns(selectedColumnNames),
    });
  };

  const onSaveColumns = () => {
    const { selectedColumnNames } = columnState;
    storage.set('savedColumnNames', selectedColumnNames);

    updateColumns(selectedColumnNames);

    setColumnState({
      selectedColumnNames,
      isSavedColumns: true,
      isDefaultColumns: getIsDefaultColumns(selectedColumnNames),
    });
  };

  const onResetColumns = () => {
    const selectedColumnNames = getDefaultColumnNames();

    updateColumns(selectedColumnNames);

    setColumnState({
      selectedColumnNames,
      isSavedColumns: true,
      isDefaultColumns: getIsDefaultColumns(selectedColumnNames),
    });
  };

  const onResetDefaultColumns = () => {
    const savedColumns = getDefaultColumnNames();

    updateColumns(defaultColumnNames);

    setColumnState({
      selectedColumnNames: defaultColumnNames,
      isSavedColumns: getIsDefaultColumns(savedColumns),
      isDefaultColumns: true,
    });
  };


  return (
    <ListBox
      data-test={'customize-results-panel'}
      selected={columnState.selectedColumnNames}
      options={columnOptions}
      onChange={onCustomizeResults}
      onSave={onSaveColumns}
      onReset={onResetColumns}
      onResetDefault={onResetDefaultColumns}
      isSavedColumns={columnState.isSavedColumns}
      isDefaultColumns={columnState.isDefaultColumns}
    />
  );
};

CustomizeResultsPanel.propTypes = {
  /** Callback function allowing access to states of columns */
  onColumnChange: T.func.isRequired,
};

export default CustomizeResultsPanel;
