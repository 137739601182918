import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureStore, serviceWorker, ConnectedMessages } from 'state-template';
import { CookiesProvider } from 'react-cookie';

import 'state-template/dist/style/core/css/cagov.core.min.css';
import 'state-template/dist/style/core/css/colorscheme-sierra.min.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import './style/style.scss';

import App from 'containers/App';
import reducer from './redux/reducer';
import saga from './redux/saga';

const reducers = {
  reducer,
};

const sagas = [
  saga,
];

const store = configureStore({ reducers, sagas });

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <CookiesProvider>
        {Number(process.env.REACT_APP_IS_MAINTENANCE_MODE) === 0 ? <App />
          : (
            <>
              <br />
              <br />
              <p style={{ textAlign: 'center', color: 'black', fontSize: '30px' }}>Cannabis Unified License Search is currently down for temporary maintenance. </p>
            </>
          )}

      </CookiesProvider>
      <ConnectedMessages />
    </Provider>
  </Router>,
  document.getElementById('root'),
);

serviceWorker.register();
