import React from 'react';
import * as T from 'prop-types';
import DualListBox from 'react-dual-listbox';
import { Button } from 'state-template';
import { MoveFocusInside } from 'react-focus-lock';

import Panel from 'components/Panel';

const ListBox = ({
  selected,
  options,
  onChange,
  onSave,
  onReset,
  onResetDefault,
  isSavedColumns,
  isDefaultColumns,
}) => (
  <Panel title={'Customize Results'}>
    <MoveFocusInside>
      <DualListBox
        preserveSelectOrder
        showNoOptionsText
        showOrderButtons
        showHeaderLabels
        lang={{ availableHeader: 'Available Columns', selectedHeader: 'Visible Columns' }}
        selected={selected}
        options={options}
        onChange={onChange}
        icons={{
          moveLeft: <span className={'fa fa-chevron-left'} title={'Remove Selected Column'} />,
          moveAllLeft: [
            <span key={0} className={'fa fa-chevron-left'} title={'Remove All Columns'} />,
            <span key={1} className={'fa fa-chevron-left'} />,
          ],
          moveRight: <span className={'fa fa-chevron-right'} title={'Add Selected Column'} />,
          moveAllRight: [
            <span key={0} className={'fa fa-chevron-right'} title={'Add All Columns'} />,
            <span key={1} className={'fa fa-chevron-right'} />,
          ],
          moveDown: <span className={'fa fa-chevron-down'} title={'Move Column Right'} />,
          moveUp: <span className={'fa fa-chevron-up'} title={'Move Column Left'} />,
        }}
      />

      <div className={'CustomizeResultsPanel__actions'}>
        <div className={'CustomizeResultsPanel__actions-left'}>
          <Button text={'Reset to Default'} onClick={onResetDefault} variant={'default'} disabled={isDefaultColumns} />
          <Button text={'Reset'} onClick={onReset} variant={'default'} disabled={isSavedColumns} />
        </div>

        <Button text={'Save as Custom View'} onClick={onSave} variant={'primary'} disabled={isSavedColumns} />
      </div>
    </MoveFocusInside>
  </Panel>
);

ListBox.propTypes = {
  /** Columns that have been selected */
  selected: T.arrayOf(T.string).isRequired,
  /** All columns to select from */
  options: T.arrayOf(
    T.shape({ value: T.string, label: T.string }),
  ).isRequired,
  /** Fired when selected columns change */
  onChange: T.func.isRequired,
  /** Fired when saving */
  onSave: T.func.isRequired,
  /** Fired when resetting back to the saved values */
  onReset: T.func.isRequired,
  /** Fired when restting to the default */
  onResetDefault: T.func.isRequired,
  /** Determines if the selected columns are the saved columns */
  isSavedColumns: T.bool.isRequired,
  /** Determines if the selected columns are the default columns */
  isDefaultColumns: T.bool.isRequired,
};

export default ListBox;
