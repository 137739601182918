import React from 'react';
import * as T from 'prop-types';
import { Marker } from 'react-leaflet';
import { leafletMarker } from 'components/LabeledMapMarker';

export const MapMarker = ({
  highlightedLicenseId,
  retailer,
  index,
  onClickBusiness,
  onBusinessHover,
  resetBusinessHover,
}) => {
  const {
    id,
    premiseLatitude,
    premiseLongitude,
  } = retailer;
  const icon = (id === highlightedLicenseId) ? leafletMarker(`${index}`, '#b71134') : leafletMarker(`${index}`, '#black');
  const zIndexOffset = (id === highlightedLicenseId) ? 10000 : 0;

  return (
    <Marker
      position={[premiseLatitude, premiseLongitude]}
      onClick={() => onClickBusiness(id)}
      onMouseOver={() => onBusinessHover(id)}
      onFocus={() => onBusinessHover(id)}
      onMouseOut={() => resetBusinessHover()}
      onBlur={() => resetBusinessHover()}
      zIndexOffset={zIndexOffset}
      icon={icon}
      keyboard={false}
    />
  );
};

MapMarker.propTypes = {
  /** ID of focused or hovered marker */
  highlightedLicenseId: T.number,
  /** Retailer Object */
  retailer: T.shape({
    id: T.number.isRequired,
    premiseLatitude: T.number.isRequired,
    premiseLongitude: T.number.isRequired,
  }).isRequired,
  /** Index of the retailer */
  index: T.number.isRequired,
  /** marker click handler */
  onClickBusiness: T.func.isRequired,
  /** marker hover handler */
  onBusinessHover: T.func.isRequired,
  /** marker mouseout / blur handler */
  resetBusinessHover: T.func.isRequired,
};

MapMarker.defaultProps = {
  highlightedLicenseId: null,
};

export default MapMarker;
