import React from 'react';
import { Routing, LinkButton, Container, AppInsightsProvider } from 'state-template';

import UtilityHeader from 'components/UtilityHeader/UtilityHeader';
import Branding from 'components/Branding/Branding';
import Footer from 'components/Footer/Footer';
import SiteFooter from 'components/SiteFooter/SiteFooter';


import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import * as T from 'prop-types';
import routes from 'routes';

import Breadcrumb from 'components/Breadcrumb';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { formatDate } from 'utils/formatData';
import { exclamation } from 'utils/svg';

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSettingsOpen: false };
  }


  componentDidMount() {
    const { getDropdowns } = this.props;
    getDropdowns();
  }

  warningMEssage = () => (
    <span>
      {exclamation}
      {' '}
      <strong>Important Note: Beta Website!</strong>
    </span>
  )

  toggleSettingsOpen = () => {
    const { isSettingsOpen } = this.state;
    this.setState({ isSettingsOpen: !isSettingsOpen });
  }

  render() {
    const { licensingAuthorities, history } = this.props;
    const { ...authority } = licensingAuthorities.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
    const { APP_INSIGHTS_KEY } = window.config;

    return (
      <>
        <UtilityHeader />
        <Branding />
        <Container className={'App__route-container'}>
          <div className={'row d-flex justify-content-between m-y-md'}>
            <span className={'col-lg-2'}>
              <Breadcrumb />
            </span>
            <span className={'col-lg-2'}>
              <LinkButton
                className={'float-right'}
                text={'File a complaint about a cannabis business'}
                to={routes.unlicensedComplaint.path}
                variant={'danger'}
              />
            </span>
          </div>
          <AppInsightsProvider history={history} appInsightsKey={APP_INSIGHTS_KEY} severityLevel={0}>
            <Routing routes={Object.values(routes)} redirect={'/'} />
          </AppInsightsProvider>
          <p className={'App_disclaimer'}>
            Last update of license data:
            {' '}
            {formatDate(authority.dataRefreshedDate)}
          </p>
        </Container>
        <Footer />
        <SiteFooter />
      </>
    );
  }
}

App.propTypes = {
  /** Request to retrieve all dropdowns */
  getDropdowns: T.func.isRequired,
  /** Provided by withRouter - Browser URL Location */
  location: T.shape({ pathname: T.string }).isRequired,
  /** Provided by withRouter - Browser history */
  history: T.shape({ push: T.func }).isRequired,
  /** Array of License Authorities */
  licensingAuthorities: T.array.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  licensingAuthorities: selectors.getLicenseAuthorities(),
});

export const mapDispatchToProps = (dispatch) => ({
  getDropdowns: () => dispatch(actions.getDropdowns()),
});

const usingRouter = withRouter(App);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);

export default withRedux;
