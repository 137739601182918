import React, { useState } from 'react';
import * as T from 'prop-types';
import { A, Button } from 'state-template';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as selectors from 'redux/selectors';


export const Breadcrumb = ({ location, history }) => {
  const { goBack } = history;
  const { pathname } = location;
  const [hasPrevLocation, setHasPrevLocation] = useState(false);
  const isDetailsPage = pathname.includes('/results/');

  const secondLinkText = 'Back to Search Results';

  if (!isDetailsPage && !hasPrevLocation) {
    setHasPrevLocation(true);
  }

  return (
    <div className={'breadcrumb'}>
      {pathname !== '/' ? <A to={{ pathname: '/' }} text={'New Search'} /> : null}
      { isDetailsPage && secondLinkText && hasPrevLocation && (
        <span>
          {' > '}
          {/* FireFox Issue: This needs to be a button (not A) or it wont actually go back. */}
          <Button onClick={goBack} text={secondLinkText} />
        </span>
      )}
    </div>
  );
};

Breadcrumb.propTypes = {
  /** Provided by withRouter - Browser URL Location */
  location: T.shape({
    search: T.string,
    pathname: T.string,
  }).isRequired,
  /** Provided by withRouter - Browser history */
  history: T.shape({
    goBack: T.func,
  }).isRequired,
  /** Location previous to details navigation */
  breadcrumbPrevLocation: T.string.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  breadcrumbPrevLocation: selectors.getBreadcrumbPrevLocation(),
});

const usingRouter = withRouter(Breadcrumb);
const usingRedux = connect(mapStateToProps)(usingRouter);

export default usingRedux;
