import React from 'react';
import '../../style/components/SiteFooter.scss';

const SiteFooter = () => (
    <section aria-label="site footer" className="site-footer">
        <div className="container">
            {/* <div className="footer-logo">
                <a href={"/"}><img className="logo-img" src={"https://files.covid19.ca.gov/img/circle-logo.png"}
                    alt={"Organization logo"} />
                </a>
            </div>
            <div className="footer-secondary-links">
                <a href="#">Secondary link 1</a>
                <a href="#">Secondary link 2</a>
                <a href="#">Secondary link 3</a>
                <a href="#">Secondary link 4</a>
            </div> */}
            <div className="footer-social-links">
                <a href="https://www.facebook.com/CACannabisDept" target={"_blank"} rel={"noopener noreferrer"}>
                    <span className="sr-only">Facebook</span>
                    <svg version="1.1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px"
                        viewBox="0 0 24 24" style={{ enablebackground: 'new 0 0 24 24'}} xmlSpace="preserve">
                        <path d="M14,12.7h3.2L17.7,9H14V6.7c0-1,0.3-1.9,1.9-1.9h2.1V1.5c-0.3,0-1.6-0.2-2.9-0.2c-2.9,0-4.9,1.7-4.9,5.1v2.9H6.9V13h3.2v9.5
        H14L14,12.7L14,12.7z" />
                    </svg>
                </a>
                <a href="https://twitter.com/CACannabisDept" target={"_blank"} rel={"noopener noreferrer"}>
                    <span className="sr-only">Twitter</span>
                    <svg version="1.1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px"
                        viewBox="0 0 24 24" style={{ enablebackground: 'new 0 0 24 24'}} xmlSpace="preserve">
                        <path
                            d="M21.2,8c0,0.2,0,0.4,0,0.6c0,6-4.6,12.9-12.9,12.9c-2.6,0-4.9-0.7-7-2c0.3,0,0.7,0.1,1,0.1c2.2,0,4.1-0.7,5.7-1.9
        c-2,0-3.6-1.4-4.2-3.1c0.3,0.1,0.6,0.1,0.9,0.1c0.4,0,0.9-0.1,1.2-0.1c-2-0.4-3.6-2.2-3.6-4.5c0,0,0,0,0-0.1c0.6,0.3,1.3,0.6,2,0.6
        c-1.2-0.9-2-2.2-2-3.8c0-0.9,0.2-1.6,0.6-2.2c2.2,2.8,5.5,4.6,9.3,4.7C12,9,12,8.6,12,8.2c0-2.5,2-4.5,4.5-4.5
        c1.3,0,2.5,0.6,3.3,1.4c1-0.2,2-0.6,2.9-1c-0.2,1.1-1,1.9-2,2.5c1-0.1,1.8-0.3,2.6-0.7C22.9,6.5,22.2,7.4,21.2,8L21.2,8z" />
                    </svg>

                </a>
                <a href="https://www.instagram.com/cacannabisdept/" target={"_blank"} rel={"noopener noreferrer"}>
                    <span className="sr-only">Instagram</span>
                    <svg version="1.1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px"
                        viewBox="0 0 24 24" style={{ enablebackground: 'new 0 0 24 24'}} xmlSpace="preserve">
                        <path d="M16.4,1.5H7.7c-3.4,0-6.2,2.7-6.2,6.2v8.8c0,3.4,2.8,6.1,6.2,6.1h8.8c3.4,0,6.1-2.8,6.1-6.1V7.6C22.5,4.2,19.8,1.5,16.4,1.5
        z M20.4,16.4c0,2.2-1.8,4.1-4.1,4.1H7.6c-2.2,0-4.1-1.8-4.1-4V7.7c0-2.2,1.8-4.1,4.1-4.1h8.8c2.2,0,4,1.8,4,4.1L20.4,16.4L20.4,16.4
        z" />
                        <path d="M12,7.1c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S14.7,7.1,12,7.1z M12,14.9c-1.6,0-2.9-1.3-2.9-2.9
        s1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9S13.6,14.9,12,14.9z" />
                        <path d="M18.5,6.9c0,0.7-0.6,1.3-1.3,1.3S16,7.6,16,6.9s0.6-1.3,1.3-1.3S18.5,6.2,18.5,6.9z" />
                    </svg>
                </a>
            </div>
        </div>
    </section>
);

export default SiteFooter;
