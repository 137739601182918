import { normalize } from 'state-template';

const schema = {
  licenseNumber: {
    name: 'licenseNumber',
    label: 'License Number',
  },
  licenseStatus: {
    name: 'licenseStatus',
    label: 'License Status',
    placeholder: 'ALL',
  },
  licenseType: {
    name: 'licenseType',
    label: 'License Type',
    placeholder: 'ALL',
  },
  licenseDesignation: {
    name: 'licenseDesignation',
    label: 'License Designation',
    placeholder: 'ALL',
  },
  // licensingAuthority: {
  //   name: 'licensingAuthority',
  //   label: 'Licensing Authority',
  //   placeholder: 'ALL',
  // },
  // licensingAuthorityId: {
  //   name: 'licensingAuthorityId',
  //   label: 'Licensing Authority Acronym',
  // },
  premiseCounty:{
    name: 'premiseCounty',
    label: 'County',
    placeholder: 'County'
  },
  licenseTerm: {
    name: 'licenseTerm',
    label: 'License Stage',
    placeholder: 'ALL',
  },
  issueDate: {
    name: 'issueDate',
    label: 'Effective Date',
  },
  expirationDate: {
    name: 'expirationDate',
    label: 'Expiration Date',
  },
  licenseStatusDate: {
    name: 'licenseStatusDate',
    label: 'License Status Date',
  },
  businessOwnerName: {
    name: 'businessOwnerName',
    label: 'Owner Name',
  },
  businessLegalName: {
    name: 'businessLegalName',
    label: 'Legal Business Name',
  },
  businessDbaName: {
    name: 'businessDbaName',
    label: 'DBA Name',
  },
  businessStructure: {
    name: 'businessStructure',
    label: 'Business Structure',
  },
  businessEmail: {
    name: 'businessEmail',
    label: 'Business Email',
  },
  businessPhone: {
    name: 'businessPhone',
    label: 'Business Phone',
    normalize: normalize.normalizePhone,
  },
  activity: {
    name: 'activity',
    label: 'Activities',
  },
};

export default schema;
