import React from 'react';
import * as T from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConnectedAsync } from 'state-template';
import * as selectors from 'redux/selectors';
import * as actions from 'redux/actions';
import routes from 'routes';

import { createStructuredSelector } from 'reselect';
import BusinessType from './FormSections/BusinessType';
import ComplaintDetails from './FormSections/ComplaintDetails';

export class UnlicensedComplaintPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    // Redirect to an external URL
    window.location.href = 'https://online.bcc.ca.gov/bcc/Cap/CapApplyDisclaimer.aspx?CAPType=Enforcement/Cannabis/Complaint/General%20Complaint&Module=Enforcement';
  }
  
  previousPage = () => {
    this.setState((state) => ({ page: state.page - 1 }));
  }

  onSubmitBusinessType = ({ licenseType }) => {
    const { licenseAuthorities, history } = this.props;
    const complaintUrlBCC = 'https://online.bcc.ca.gov/bcc/Cap/CapApplyDisclaimer.aspx?CAPType=Enforcement/Cannabis/Complaint/General%20Complaint&Module=Enforcement';
    //const complaintUrlMCSB = 'https://www.cdph.ca.gov/mcsb/fileacomplaint';
    const licenseAuthority = licenseAuthorities.find((authority) => authority.id.toLowerCase() === licenseType.licensingAuthorityId.toLowerCase());


    if (licenseAuthority.id === 'BCC') {
      window.open(complaintUrlBCC, '_blank');
      history.push(routes.home.path);
    } else if (licenseAuthority.id === 'MCSB') {
      window.open(complaintUrlBCC, '_blank');
      history.push(routes.home.path);
    } else {
      //this.setState({ page: 2, licenseAuthority });
      window.open(complaintUrlBCC, '_blank');
    }
  }

  onCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { onSubmit, history } = this.props;
    const { page, licenseAuthority } = this.state;

    return (
      <div>
        <ConnectedAsync>
          <h2>Redirecting...</h2>

          {/* {page === 1 && <BusinessType onCancel={this.onCancel} onSubmit={this.onSubmitBusinessType} />} */}

          {/* Default Complaint Page */}
          {/* {page === 2 && (
            <ComplaintDetails
              onCancel={this.onCancel}
              onPrevious={this.previousPage}
              onSubmit={(values) => onSubmit({ values, history })}
              licenseAuthority={licenseAuthority}
            />
          )} */}
        </ConnectedAsync>
      </div>
    );
  }
}

UnlicensedComplaintPage.propTypes = {
  /** Provided by withRouter - browser history */
  history: T.shape({
    goBack: T.func,
    push: T.func,
  }).isRequired,
  /** Submits the form */
  onSubmit: T.func.isRequired,
  /** Array of License Authorities */
  licenseAuthorities: T.array.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  licenseAuthorities: selectors.getLicenseAuthorities(),
});

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => dispatch(actions.postUnlicensedComplaint(values)),
});

const usingRouter = withRouter(UnlicensedComplaintPage);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);

export default usingRedux;
