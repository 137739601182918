import React from 'react';
import * as T from 'prop-types';

import { FieldDate } from 'state-template';

const FieldDateRange = ({
  className, label, name,
}) => {
  const labelId = `FieldDateRange__${label}`;
  const startProps = {
    'aria-label': `Start of ${label} Range`,
    className: 'FieldDateRange__field',
    name: `${name}From`,
  };
  const endProps = {
    'aria-label': `End of ${label} Range`,
    className: 'FieldDateRange__field',
    name: `${name}To`,
  };

  return (
    <div className={className} role={'group'} aria-label={labelId}>
      <div id={labelId} className={'FieldDateRange__group-label'}>{label}</div>

      <div className={'FieldDateRange__fields'}>
        <div className={'FieldDateRange__label'}>From</div>
        <FieldDate {...startProps} />

        <div className={'FieldDateRange__label'}>To</div>
        <FieldDate {...endProps} />
      </div>
    </div>
  );
};

FieldDateRange.propTypes = {
  /** Class to apply to top level for syling */
  className: T.string,
  /** Label of the field to create a range for */
  label: T.string.isRequired,
  /** Name of the field to create a range for */
  name: T.string.isRequired,
};

FieldDateRange.defaultProps = {
  className: '',
};

export default FieldDateRange;
