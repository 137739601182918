import React from 'react';
import * as T from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button } from 'state-template';
import SearchBoxAutoComplete from 'components/SearchBoxAutoComplete/SearchBoxAutoComplete';
import { minLength3 } from 'utils/validate';

export const LocationSearchForm = ({ handleSubmit, name, autoSuggest, onSubmit }) => {
  const onSearch = (value) => {
    handleSubmit(() => {
      onSubmit({ [name]: value });
    })();
  };

  return (
    <form
      onSubmit={onSearch}
      name={'locationSearch'}
      autoComplete={'off'}
      className={'location-search-box'}
    >
      <SearchBoxAutoComplete
        name={'query'}
        aria-label={'search'}
        validate={[minLength3]}
        autoSuggest={autoSuggest}
        onSearch={onSearch}
        placeholder={'Search for a location...'}
      />
      <Button
        iconProps={{ name: 'search-right', srOnly: 'Submit Search' }}
        className={'SearchBox__icon'}
        type={'submit'}
        data-test={'submit-button'}
        onClick={handleSubmit}
      />
    </form>
  );
};

LocationSearchForm.propTypes = {
  /** Provided by redux-form, submits the form to redux-form using the `onSubmit` prop */
  handleSubmit: T.func.isRequired,
  /** Field Name */
  name: T.string.isRequired,
  /** Formatted array of autosuggestions */
  autoSuggest: T.array,
  /** Submission callback */
  onSubmit: T.func.isRequired,
};

LocationSearchForm.defaultProps = {
  autoSuggest: null,
};

const usingForm = reduxForm({
  form: 'locationSearch',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(LocationSearchForm);

export default usingForm;
