import { normalize, date } from 'state-template';

export const formatRecord = (record) => {
  const notAvailable = process.env.REACT_APP_DATA_NOT_AVAILABLE;
  const notPublished = process.env.REACT_APP_NOT_PUBLISHED;

  const zipCodeAvailable = record.premiseZipCode !== notAvailable;
  const cityAvailable = record.premiseCity !== notAvailable;
  const streetAvailable = record.premiseStreet !== notAvailable;
  const streetPublished = record.premiseStreet !== notPublished;
  const phoneAvailable = record.businessPhone !== notAvailable;
  const issueDateAvailable = record.issueDate !== notAvailable;
  const expirationDateAvailable = record.expirationDate !== notAvailable;

  // If street name is not published, then reduce ZIP to five digits.
  // Otherwise, hyphenated nine-digit ZIPs.
  let premiseZipCode = notAvailable;
  if (zipCodeAvailable) {
    if (!streetPublished || !streetAvailable) {
      premiseZipCode = normalize.normalizeZip(record.premiseZipCode);
    } else {
      premiseZipCode = normalize.hyphenateZip(record.premiseZipCode);
    }
  }

  // We decided to assemble the address with "NOT PUBLISHED" included if applicable.
  // Ex: NOT PUBLISHED, ELK GROVE, CA, 95757
  let premiseAddress = notAvailable;
  if ((!streetPublished || !streetAvailable) && (cityAvailable && zipCodeAvailable)) {
    premiseAddress = `${notPublished}, ${record.premiseCity}, CA, ${premiseZipCode}`;
  } else if (streetPublished || streetAvailable) {
    premiseAddress = `${record.premiseStreetAddress}, ${record.premiseCity}, CA, ${premiseZipCode}`;
  }

  const businessPhone = phoneAvailable ? normalize.normalizePhone(record.businessPhone) : notAvailable;
  const issueDate = issueDateAvailable ? date.formattedDate(record.issueDate) : notAvailable;
  const expirationDate = expirationDateAvailable ? date.formattedDate(record.expirationDate) : notAvailable;
  const licenseStatusDate = record.licenseStatusDate ? date.formattedDate(record.licenseStatusDate) : notAvailable;

  return {
    ...record,
    premiseZipCode,
    premiseAddress,
    businessPhone,
    issueDate,
    expirationDate,
    licenseStatusDate,
  };
};

export const formatDropdowns = (dropdown) => dropdown.map((item) => ({
  id: item.id,
  value: item.name,
  label: item.name,
}));

export const formatCountiesDropdown = (dropdown) => dropdown.map((item) => ({
  id: item.id,
  value: item.name,
  label: item.countyState,
}));


export const formatlicenseBusinessType = (type) => type.map((item) => ({
  key: item.id,
  value: item,
  label: item.name,
}));

export const formatDate = (unformattedDate) => {
  const d = new Date(unformattedDate);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) { month = `0${month}`; }
  if (day.length < 2) { day = `0${day}`; }

  return [month, day, year].join('/');
};

export const formatAutoSuggest = (autoSuggest) => autoSuggest.map((location) => ({
  key: `${location.address.formattedAddress}${location.name}`,
  label: location.name ? `${location.name} - ${location.address.formattedAddress}` : location.address.formattedAddress,
  value: location.name ? `${location.name} - ${location.address.formattedAddress}` : location.address.formattedAddress,
}));

export const sortLicenseType = (a, b) => {
  if (a.value.includes('Type 6') && b.value.includes('Type 7')) {
    return 1;
  }
  if (a.value.includes('Type 7') && b.value.includes('Type 6')) {
    return -1;
  }
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
};
