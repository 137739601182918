import DetailsPage from 'containers/DetailsPage';
import HomePage from 'containers/HomePage';
import ResultsPage from 'containers/ResultsPage';
import RetailersMapPage from 'containers/RetailersMapPage';
import UnlicensedComplaintPage from 'containers/UnlicensedComplaintPage';
import ComplaintSuccessPage from 'containers/UnlicensedComplaintPage/ComplaintSuccessPage';

const routes = {
  home: {
    key: 'home',
    path: '/',
    exact: true,
    component: HomePage,
    name: 'Home',
    hidden: false,
    icon: 'ca-gov-icon-home',
  },
  results: {
    key: 'results',
    path: '/results',
    exact: true,
    component: ResultsPage,
    hidden: true,
  },
  details: {
    key: 'details',
    path: '/results/:id',
    exact: true,
    component: DetailsPage,
    hidden: true,
  },
  retailersMap: {
    key: 'retailersMapPage',
    path: '/retailers',
    exact: true,
    component: RetailersMapPage,
    hidden: true,
  },
  unlicensedComplaint: {
    key: 'unlicensedComplaint',
    path: '/complaints/unlicensed',
    exact: true,
    component: UnlicensedComplaintPage,
    hidden: true,
  },
  unlicensedComplaintSuccess: {
    key: 'unlicensedComplaintSuccess',
    path: '/complaints/SubmissionSuccess',
    exact: true,
    component: ComplaintSuccessPage,
    hidden: true,
  },
};

export default routes;
