import React from 'react';
import * as T from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';

import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import { parsePoint, parseBounds } from 'utils/coordinates';
import LocateControl from '../LocateControl';

export class Map extends React.Component {
  constructor(props) {
    super(props);
    this.map = React.createRef();
  }

  populateMap = (leafletElement) => {
    const { getRetailers } = this.props;
    const bounds = leafletElement.getBounds();
    getRetailers(parseBounds(bounds));
  }

  componentDidMount = () => {
    const { updateUserLocationInit } = this.props;
    const { leafletElement } = this.map.current;
    this.populateMap(leafletElement);
    updateUserLocationInit(false);
  }

  onViewportChanged = () => {
    const { updateGeolocation } = this.props;
    const { leafletElement } = this.map.current;
    const center = {
      ...parsePoint(leafletElement.getCenter()),
      zoom: leafletElement.getZoom(),
    };
    updateGeolocation(center);
    this.populateMap(leafletElement);
  }

  onLocationFound = (e) => {
    const { updateGeolocation, updateUserGeolocation, isUserLocationInit, updateUserLocationInit } = this.props;

    updateUserGeolocation({
      latitude: e.latitude,
      longitude: e.longitude,
    });

    if (!isUserLocationInit) {
      updateGeolocation({
        latitude: e.latitude,
        longitude: e.longitude,
      });
    }
    updateUserLocationInit(true);
  }

  render() {
    const { geolocation, isUserLocationInit, markers } = this.props;
    const center = [geolocation.latitude, geolocation.longitude];
    const { zoom } = geolocation;
    const locatorOptions = {
      setView: true,
      flyTo: true,
      keepCurrentZoomLevel: true,
    };

    return (
      <>
        <div className={'map-container'}>
          <div id={'map'}>
            <LeafletMap
              ref={this.map}
              center={center}
              zoom={zoom || 14}
              onLocationFound={(e) => (!isUserLocationInit ? this.onLocationFound(e) : null)}
              onmoveend={this.onViewportChanged}
              style={{ height: '100%' }}
            >
              <TileLayer
                attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
              />
              <LocateControl options={locatorOptions} />
              { markers.length > 0 && markers }
            </LeafletMap>
          </div>
        </div>
      </>
    );
  }
}

Map.propTypes = {
  /** Array of retailer markers within bounds */
  markers: T.array.isRequired,
  /** current location */
  geolocation: T.object.isRequired,
  /** update location on viewport change */
  updateGeolocation: T.func.isRequired,
  /** update user geolocation */
  updateUserGeolocation: T.func.isRequired,
  /** gets list of retails within bounds */
  getRetailers: T.func.isRequired,
  /** Provided by withRouter - Browser history */
  history: T.shape({ push: T.func }).isRequired,
  /** Updates redux with whether or not user location has been initialized */
  updateUserLocationInit: T.func.isRequired,
  /** is location initialized */
  isUserLocationInit: T.bool.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  geolocation: selectors.getGeolocation(),
  locationAutoSuggest: selectors.getLocationAutoSuggest(),
  userGeolocation: selectors.getUserGeolocation(),
  isRetailersLoading: selectors.getRetailersLoading(),
  isUserLocationInit: selectors.getUserLocationInit(),
});

export const mapDispatchToProps = (dispatch) => ({
  getRetailers: (bounds) => dispatch(actions.getRetailers(bounds)),
  updateGeolocation: (geolocation) => dispatch(actions.updateGeolocation(geolocation)),
  updateUserGeolocation: (userGeolocation) => dispatch(actions.updateUserGeolocation(userGeolocation)),
  updateUserLocationInit: (userlocationInit) => dispatch(actions.updateUserLocationInit(userlocationInit)),
});

const usingRouter = withRouter(Map);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);

export default usingRedux;
