import React, { useEffect, useState } from 'react';
import * as T from 'prop-types';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';

import { Button } from 'state-template';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import Modal from 'components/Modal';
import routes from '../../../routes';

// eslint-disable-next-line react/prop-types
export const ComplaintSuccessPage = ({
  complaintResponse,
  resetComplaint,
  history,
  licenseAuthorities,
}) => {
  const {
    submitted,
    complainantFirstName,
    complainantLastName,
    complainantPhone,
    complainantEmail,
    businessName,
    businessAddress,
    licenseType,
    complaintDetails,
    complaintSource,
    complaintSourceDetail,
    isFiledAnonymously,
  } = complaintResponse;

  if (!submitted) {
    history.push(routes.home.path);
  }
  // const licenseAuthority = licenseAuthorities.find((x) => (x.id === licenseType.licensingAuthorityId));

  const [isModalOpen, setIsModalOpen] = useState(true);
  useEffect(() => () => {
    resetComplaint({ submitted: false });
  }, [resetComplaint]);

  const onDismiss = () => {
    setIsModalOpen(false);
  };

  const SucessModal = () => (
    <Modal
      isOpen={isModalOpen}
      onDismiss={onDismiss}
      id={'complaint-sucess-modal'}
      title={'Submissions Success'}
    >
      <div className={'m-b-md'}>
      Your Complaint has been successfully Submitted
        <div className={'float-right'}>
          <Button onClick={onDismiss} text={'Close'} variant={'default'} />
        </div>
      </div>
    </Modal>
  );


  // eslint-disable-next-line react/prop-types
  const ComplaintSuccessField = ({ label, value }) => (
    <span className={'col-sm-5 m-y-sm'}>
      <strong>{`${label} `}</strong>
      <span>{value}</span>
    </span>
  );


  return submitted ? (
    <>
      <h2>Your Complaint Has Been Successfully Submitted</h2>
      { !isFiledAnonymously && (
        <div className={'UnlicensedComplaintPage__section'}>
          <h3 className={'col-12'}>Submitter Information</h3>
          <div className={'row justify-content-md-center'}>
            <ComplaintSuccessField label={'First Name:'} value={complainantFirstName} />
            <ComplaintSuccessField label={'Last Name:'} value={complainantLastName} />
          </div>
          <div className={'row justify-content-md-center m-b-md'}>
            <ComplaintSuccessField label={'Phone Number:'} value={complainantPhone} />
            <ComplaintSuccessField label={'Email:'} value={complainantEmail} />
          </div>
        </div>
      )}
      <div className={'UnlicensedComplaintPage__section'}>
        <h3 className={'col-12'}>Complaint Submission</h3>
        <div className={'row justify-content-md-center'}>
          <ComplaintSuccessField label={'Business Name:'} value={businessName} />
          <ComplaintSuccessField label={'Location:'} value={businessAddress} />
        </div>
        <div className={'row justify-content-md-center'}>
          <ComplaintSuccessField label={'License Type:'} value={licenseType.name} />
          {/* <ComplaintSuccessField label={'License Authority:'} value={licenseAuthority.name} /> */}
        </div>
        <div className={'col-sm m-t-md'}>
          <strong className={'ComplaintSucessPage__label'}>Complaint Details</strong>
          <blockquote>
            <div className={'m-a-md'}>{complaintDetails}</div>
          </blockquote>
        </div>
        <div className={'col-sm m-t-md'}>
          <strong>How do you know this Information?</strong>
          <span className={'m-a-md'}>{complaintSource}</span>
        </div>
        { complaintSourceDetail
        && (
        <div className={'col-sm m-t-md'}>
          <strong className={'ComplaintSucessPage__label'}>If other, please describe</strong>
          <blockquote>
            <div className={'m-a-md'}>{complaintSourceDetail}</div>
          </blockquote>
        </div>
        ) }
      </div>

      { isModalOpen
      && (
        <SucessModal />
      )}
    </>
  ) : null;
};

ComplaintSuccessPage.propTypes = {
  /** Provided by withRouter - browser history */
  history: T.shape({
    push: T.func,
  }).isRequired,
  licenseAuthorities: T.array.isRequired,
  complaintResponse: T.shape({
    submitted: T.bool.isRequired,
    complainantFirstName: T.string,
    complainantLastName: T.string,
    complainantPhone: T.string,
    complainantEmail: T.string,
    businessName: T.string,
    businessAddress: T.string,
    licenseType: T.object,
    complaintDetails: T.string,
    complaintSource: T.string,
    isFiledAnonymously: T.bool,
    complaintSourceDetail: T.string,
  }).isRequired,
  resetComplaint: T.func.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  licenseAuthorities: selectors.getLicenseAuthorities(),
  complaintResponse: selectors.getUnlicensedComplaintResponse(),
});

export const mapDispatchToProps = (dispatch) => ({
  resetComplaint: (values) => dispatch(actions.updateUnlicensedComplaint(values)),
});

const usingRouter = withRouter(ComplaintSuccessPage);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);

export default usingRedux;
