import { validate, fieldOptions } from 'state-template';
import { fileSizeValidation, fileTypeValidation, maxLength500, maxLength3000, maxLength50, maxLength20, phoneValidation } from 'utils/validate';
import { normalizePhone } from 'utils/normalize';

const complaintsSchema = {
  // submitter information
  fileAnonymously: {
    name: 'isFiledAnonymously',
    label: 'Do you want to file anonymously?',
    options: fieldOptions.yesNoOptions,
    'aria-labelledby': 'isFiledAnonymously--label',
  },
  firstName: {
    name: 'complainantFirstName',
    label: 'First Name (optional)',
    validate: maxLength50,
  },
  lastName: {
    name: 'complainantLastName',
    label: 'Last Name (optional)',
    validate: maxLength50,
  },
  phone: {
    name: 'complainantPhone',
    label: 'Phone (optional)',
    placeholder: '###-###-####',
    normalize: normalizePhone,
    validate: maxLength20,
    warn: phoneValidation,
  },
  email: {
    name: 'complainantEmail',
    label: 'Email (optional)',
    validate: [validate.isValidEmail, maxLength50],
  },
  // complaint information
  businessName: {
    name: 'businessName',
    label: 'Business Name (if known)',
    validate: maxLength50,
  },
  businessLocation: {
    name: 'businessAddress',
    label: 'Facility Location/Address (if known)',
    validate: maxLength50,
  },
  complaintDetails: {
    name: 'complaintDetails',
    label: 'Complaint Details',
    tag: 'textarea',
    validate: [maxLength3000],
  },
  complaintSource: {
    name: 'complaintSource',
    label: 'How do you know this information?',
    options: [
      { value: 'Direct Knowledge', label: 'Direct Knowledge' },
      { value: 'Internet', label: 'Internet' },
      { value: 'Third Party', label: 'Third Party' },
      { value: 'Other', label: 'Other' },
    ],
  },
  complaintSourceDetail: {
    name: 'complaintSourceDetail',
    label: 'If other, please describe',
    validate: [maxLength500],
    'aria-labelledby': 'complaintSourceDetail--label',
  },
  supportingDocuments: {
    name: 'attachmentFiles',
    label: 'Upload an Image or Document to Support Complaint Investigation',
    multiple: true,
    validate: [fileSizeValidation, fileTypeValidation],
  },
  reCaptchaToken: {
    name: 'reCaptchaToken',
    size: 'invisible',
  },
};

export default complaintsSchema;
