import { api } from 'state-template';
import qs from 'query-string';
import { normalizeAllSpecialChars } from '../utils/normalize';

const { CANNA_API, FUNC_API } = window.config;

export const constructSearchUri = (action) => {
  const isAdvancedSearch = !Object.prototype.hasOwnProperty.call(action.payload, 'searchQuery');
  const searchType = isAdvancedSearch ? 'AdvancedSearch' : 'filteredSearch';
  const queryString = qs.stringify(action.payload);
  return `${CANNA_API}/licenses/${searchType}?${queryString}`;
};

export const getResultsRequest = async (action) => {
  const url = constructSearchUri(action);
  const data = await api.request(url);
  data.metadata.sort = action.payload.sortOrder
    ? {
      dataField: action.payload.sortOrder.split(' ')[0],
      order: action.payload.sortOrder.split(' ')[1],
    } : null;
  return data;
};

export const getDetailsRequest = async (action) => {
  const url = `${CANNA_API}/licenses/${action.payload}`;
  const data = await api.request(url);
  return data;
};

export const getRetailersRequest = async (action) => {
  const params = [
    `minLatitude=${action.payload.minLatitude}`,
    `maxLatitude=${action.payload.maxLatitude}`,
    `minLongitude=${action.payload.minLongitude}`,
    `maxLongitude=${action.payload.maxLongitude}`,
  ].join('&');
  const url = `${CANNA_API}/licenses/RetailerLocationSearch?${params}&pageSize=50`;
  const data = await api.request(url);
  return data;
};

export const postUnlicensedComplaint = async (values) => {
  const url = `${CANNA_API}/complaints`;
  const { licenseType, attachmentFiles, ...body } = values;

  if (body.complainantPhone) {
    body.complainantPhone = normalizeAllSpecialChars(body.complainantPhone);
  }

  body.licensingAuthorityId = values.licenseType.licensingAuthorityId;

  const formData = new FormData();

  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });

  if (attachmentFiles) {
    attachmentFiles.forEach((file) => {
      formData.append('attachmentFiles', file, file.name);
    });
  }

  const options = {
    method: 'POST',
    body: formData,
    headers: {},
  };
  const data = await api.request(url, options);
  return data;
};

export const getAutoSuggestLocationRequest = async (values) => {
  const queryString = qs.stringify(values);
  const url = `${FUNC_API}/AutoSuggest?${queryString}&culture=en-US&userRegion=US`;
  const data = await api.request(url);
  return data.resourceSets[0].resources[0].value;
};

export const getLocationRequest = async (values) => {
  const queryString = qs.stringify(values);
  const url = `${FUNC_API}/locations?${queryString}&culture=en-US&include=queryParse`;
  const data = await api.request(url);
  const location = {
    latitude: data.resourceSets[0].resources[0].point.coordinates[0],
    longitude: data.resourceSets[0].resources[0].point.coordinates[1],
  };
  return location;
};

export const generateSimpleRequest = (url) => async () => {
  const data = await api.request(url);
  return data;
};

export const getCountiesRequest = generateSimpleRequest(`${CANNA_API}/counties`);
export const getLicenseTypesRequest = generateSimpleRequest(`${CANNA_API}/licensetypes`);
export const getLicenseStatusesRequest = generateSimpleRequest(`${CANNA_API}/licensestatuses`);
export const getLicenseTermsRequest = generateSimpleRequest(`${CANNA_API}/licenseterms`);
export const getLicenseDesignationsRequest = generateSimpleRequest(`${CANNA_API}/licensedesignations`);
export const getLicenseAuthoritiesRequest = generateSimpleRequest(`${CANNA_API}/licensingauthorities`);
export const getLicenseBusinessTypesRequest = generateSimpleRequest(`${CANNA_API}/licensebusinesstypes`);

export const generateRequestError = (error) => {
  let errorMessage = '';

  Object.keys(error).forEach((err) => {
    errorMessage += `${error[err]} \n`;
  });

  return errorMessage;
};
