import React, { createRef } from 'react';
import * as T from 'prop-types';
import { Icon } from 'state-template';
import FocusLock from 'react-focus-lock';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutside);
    document.addEventListener('keydown', this.onEscape);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutside);
    document.removeEventListener('keydown', this.onEscape);
  }

  onEscape = (event) => {
    const { onDismiss } = this.props;
    if (event.key === 'Escape') {
      onDismiss();
    }
  }

  onClickOutside = (event) => {
    const { onDismiss } = this.props;

    if (event.target === this.modalRef.current) {
      onDismiss();
    }
  }

  render() {
    const {
      id,
      title,
      onDismiss,
      children,
    } = this.props;

    const labelId = `${id}-label`;

    return (
      <FocusLock>
        <div className={'Modal'} ref={this.modalRef} role={'dialog'} aria-labelledby={labelId} tabIndex={'-1'}>
          <div className={'Modal__content'} role={'document'}>
            <div className={'Modal__header'}>
              <h3 className={'Modal__header-title'} id={labelId}>{title}</h3>
              <Icon name={'close-mark'} srOnly={'Dismiss'} onClick={onDismiss} className={'Modal__dismiss'} />
            </div>

            <div className={'Modal__body'}>
              {children}
            </div>
          </div>
        </div>
      </FocusLock>
    );
  }
}

Modal.propTypes = {
  /** Unique id for modal */
  id: T.string.isRequired,
  /** Title to render */
  title: T.string.isRequired,
  /** Fires when dismissing the modal */
  onDismiss: T.func.isRequired,
  /** Content to render */
  children: T.node.isRequired,
};

export default Modal;
