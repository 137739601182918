import types from './mimeTypes';

export const minLength = (min) => (value) => (
  value && value.length < min
    ? `Must be ${min} characters or more`
    : undefined
);

export const minLength3 = minLength(3);

export const maxLength = (max) => (value) => (
  value && value.length > max
    ? `Must be ${max} characters or less`
    : undefined
);

export const maxLength3000 = maxLength(3000);
export const maxLength500 = maxLength(500);
export const maxLength50 = maxLength(50);
export const maxLength20 = maxLength(20);

export const phoneValidation = (value) => {
  if (value && value.match(/\d+/g)?.join('').length !== 10) {
    return 'Non-standard 10 digit phone number entered';
  }
  return undefined;
};

export const fileSize = (errorMessage, maxFileSize) => (fileList) => {
  let size = 0;
  if (fileList && fileList.length > 0) {
    fileList.forEach((file) => {
      size += file.size;
    });
    if (size >= maxFileSize) {
      return errorMessage;
    }
  }
  return undefined;
};

export const fileSizeValidation = fileSize('Maximum upload size is 25mb', 25000000);

export const fileType = (errorMessage, mimeTypes) => (fileList) => {
  let isValidFileType = true;
  if (fileList && fileList.length > 0) {
    fileList.forEach((file) => {
      isValidFileType = !file.type.includes(mimeTypes);
      if (!mimeTypes.includes(file.type)) {
        isValidFileType = false;
      }
    });
    if (!isValidFileType) {
      return errorMessage;
    }
  }
  return undefined;
};

export const fileTypeValidation = fileType('Accepts only jpg, png, csv, doc, docx, pdf, gif, .mp4, and .mov', types);

export const validateSearchEmpty = (value) => (
  !value
    ? 'Enter a license number, status, type, owner name, business name (legal or DBA), address, city, zip, or county'
    : undefined
);
