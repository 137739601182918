export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';

export const UPDATE_GEOLOCATION = 'UPDATE_GEOLOCATION';
export const UPDATE_USER_GEOLOCATION = 'UPDATE_USER_GEOLOCATION';

export const GET_RESULTS = 'GET_RESULTS';
export const GET_RESULTS_SUCCESS = 'GET_RESULTS_SUCCESS';
export const GET_RESULTS_METADATA = 'GET_RESULTS_METADATA';

export const GET_DROPDOWNS = 'GET_DROPDOWNS';

export const GET_COUNTIES_SUCCESS = 'GET_COUNTIES_SUCCESS';

export const GET_LICENSE_TYPES_SUCCESS = 'GET_LICENSE_TYPES_SUCCESS';

export const GET_LICENSE_STATUSES_SUCCESS = 'GET_LICENSE_STATUSES_SUCCESS';

export const GET_LICENSE_TERMS_SUCCESS = 'GET_LICENSE_TERMS_SUCCESS';

export const GET_LICENSE_DESIGNATIONS_SUCCESS = 'GET_LICENSE_DESIGNATIONS_SUCCESS';

export const GET_LICENSE_AUTHORITIES_SUCCESS = 'GET_LICENSE_AUTHORITIES_SUCCESS';

export const GET_LICENSE_BUSINESS_TYPES_SUCCESS = 'GET_LICENSE_BUSINESS_TYPES_SUCCESS';

export const GET_EXPORT = 'GET_EXPORT';

export const GET_TABLE_LOADING = 'GET_TABLE_LOADING';

export const GET_DETAILS = 'GET_LICENSE_DETAILS';
export const GET_DETAILS_SUCCESS = 'GET_LICENSE_DETAILS_SUCCESS';

export const GET_RETAILERS = 'GET_RETAILERS';
export const GET_RETAILERS_SUCCESS = 'GET_RETAILERS_SUCCESS';
export const UPDATE_RETAILERS_LOADING = 'UPDATE_RETAILERS_LOADING';

export const GET_MODIFIED_RESULTS = 'GET_MODIFIED_RESULTS';
export const GET_MODIFIED_RESULTS_SUCCESS = 'GET_MODIFIED_RESULTS_SUCCESS';

export const POST_UNLICENSED_COMPLAINT = 'POST_UNLICENSED_COMPLAINT';
export const UPDATE_UNLICENSED_COMPLAINT = 'UPDATE_UNLICENSED_COMPLAINT';
export const POST_UNLICENSED_COMPLAINT_SUCCESS = 'POST_UNLICENSED_COMPLAINT_SUCCESS';

export const GET_LOCATON_AUTO_SUGGEST_SUCCESS = 'GET_LOCATON_AUTO_SUGGEST_SUCCESS';
export const GET_LOCATON_AUTO_SUGGEST = 'GET_LOCATON_AUTO_SUGGEST';

export const GET_LOCATON = 'GET_LOCATON';
export const GET_LOCATON_SUCCESS = 'GET_LOCATON_SUCCESS)';

export const UPDATE_BREADCRUMB_LOCATION = 'UPDATE_BREADCRUMB_LOCATION';

export const UPDATE_USER_LOCATION_INIT = 'UPDATE_USER_LOCATION_INIT';
