import React from 'react';
import * as T from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Map, TileLayer, Marker, Popup,
} from 'react-leaflet';
import { createStructuredSelector } from 'reselect';
import { ConnectedAsync } from 'state-template';

import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import { circleMarker, leafletMarker } from 'components/LabeledMapMarker';
import { formatDate } from 'utils/formatData';
import { checkmark, exclamation, idcard } from 'utils/svg';
import LicensingContact from './LicensingContact';

const DetailMap = (props) => {
  const {
    latitude, longitude, businessName, zoom, // eslint-disable-line react/prop-types
  } = props;
  const position = [latitude, longitude];

  return (
    <div id={'map'}>
      <Map center={position} zoom={zoom || 14} style={{ height: '100%' }}>
        <TileLayer
          attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
          url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
        />
        <Marker
          position={position}
          icon={leafletMarker(null, 'black')}
        >
          <Popup>
            {businessName}
          </Popup>
        </Marker>
      </Map>
    </div>
  );
};

export class DetailsPage extends React.Component {
  componentDidMount() {
    const { getDetails, match } = this.props;
    getDetails(match.params.id);
  }

  render() {
    const { details } = this.props;
    const {
      licenseNumber,
      licenseStatus,
      licenseTerm,
      licenseType,
      licenseDesignation,
      issueDate,
      expirationDate,
      // licensingAuthority,
      businessLegalName,
      businessDbaName,
      businessOwnerName,
      businessStructure,
      businessEmail,
      businessPhone,
      premiseStreetAddress,
      premiseCounty,
      premiseCity,
      premiseState,
      premiseZipCode,
      premiseLatitude,
      premiseLongitude,
      // licensingAuthorityId,
      activity,
      licenseStatusDate,
      dataRefreshedDate,
    } = details;
    const businessName = (businessDbaName !== process.env.REACT_APP_DATA_NOT_AVAILABLE) ? businessDbaName : businessLegalName;
    const statusIcon = (licenseStatus === process.env.REACT_APP_ACTIVE) ? checkmark : exclamation;
    const statusColor = (licenseStatus === process.env.REACT_APP_ACTIVE) ? 'license-card-active' : 'license-card-not-active';
    const businesssActivities = (activity !== 'NOT APPLICABLE FOR THIS LICENSE TYPE' && activity !== process.env.REACT_APP_DATA_NOT_AVAILABLE) ? <span className={'info'}>{`Activities: ${activity}`}</span> : null;

    return (
      <div className={'DetailsPage'}>
        <ConnectedAsync>
          <h2>{businessName}</h2>
          <div className={'container row'}>
            <div className={'col-md'}>
              <section>
                <h3>License Information</h3>
                <div className={`license-card ${statusColor} d-flex flex-row align-items-start`}>
                  {statusIcon}
                  <div>
                    <span className={'info status'} title={'License Status'}>{licenseStatus}</span>
                    <span className={'info number'}>
                      Lic. No.
                      {' '}
                      <strong>{licenseNumber}</strong>
                      {' '}
                      (
                      {licenseTerm}
                      )
                    </span>
                    <span className={'info'} title={'License Type'}>{licenseType}</span>
                    {businesssActivities}
                    <span className={'info'} title={'License Designation'}>{licenseDesignation}</span>
                    <span className={'info'} title={'License Issued Date'}>
                      Effective on
                      {' '}
                      <strong>{issueDate}</strong>
                      {' '}
                      {/* by
                      {' '}
                      {licensingAuthority} */}
                    </span>
                    <span className={'info'} title={'License Expiration Date'}>
                      Expires on
                      {' '}
                      <strong>{expirationDate}</strong>
                    </span>
                    { licenseStatusDate !== process.env.REACT_APP_DATA_NOT_AVAILABLE
                      && (
                      <span className={'info'}>
                        License Status Change on
                        {' '}
                        <strong>{licenseStatusDate}</strong>
                      </span>
                      )}
                  </div>
                </div>
              </section>

              <section className={'mt-auto'}>
                <h3>Business Information</h3>
                <div className={'license-card license-card-business d-flex flex-row align-items-start'}>
                  {idcard}
                  <div>
                    {businessDbaName !== process.env.REACT_APP_DATA_NOT_AVAILABLE
                    && <span className={'info status'} title={'DBA Name'}>{businessDbaName}</span>}
                    <span className={'info'} title={'Legal Buisness Name'}>
                      Legally named
                      {' '}
                      <strong>{businessLegalName}</strong>
                    </span>
                    <span className={'info'} title={'Buisness Entity Type'}>
                      Registered as
                      {' '}
                      {businessStructure}
                    </span>
                    <span className={'info'} title={'List of Owners or Primary Contact'}>
                      {/* {licensingAuthority === 'Manufactured Cannabis Safety Branch (MCSB)' ? 'Primary Contact: ' : 'Owned by '}
                      {' '} */}
                      {businessOwnerName}
                    </span>
                    <span className={'info'} title={'Business Email'}>{businessEmail}</span>
                    <span className={'info'} title={'Business Phone Number'}>{businessPhone}</span>
                  </div>
                </div>
              </section>
            </div>
            <div className={'col-md'}>
              <section>
                <h3>Location</h3>
                <div className={'license-card license-card-location d-flex flex-row align-items-start'} title={'Premise Address'}>
                  {circleMarker(null, 'black')}
                  { premiseStreetAddress === process.env.REACT_APP_NOT_PUBLISHED
                    ? (
                      <div>
                        <span className={'info'}>{premiseStreetAddress}</span>
                        {premiseCounty !== process.env.REACT_APP_DATA_NOT_AVAILABLE
                          ? (
                            <span className={'info'}>
                              {`${premiseCounty} County, ${premiseState}`}
                            </span>
                          ) : null}
                      </div>
                    )
                    : (
                      <div>
                        <div className={'info'}>{premiseStreetAddress}</div>
                        <div className={'info'}>{`${premiseCity}, ${premiseState} ${premiseZipCode}`}</div>
                        <div className={'info'}>{`${premiseCounty} County`}</div>
                      </div>
                    )}
                </div>
                {(
                  (Object.entries(details).length)
                  && (premiseStreetAddress !== process.env.REACT_APP_NOT_PUBLISHED)
                  && (premiseStreetAddress !== process.env.REACT_APP_DATA_NOT_AVAILABLE)
                ) && (
                  <DetailMap
                    latitude={premiseLatitude}
                    longitude={premiseLongitude}
                    businessName={businessName}
                  />
                )}
              </section>
            </div>
          </div>
          <div>
            Data for this record refreshed on:
            {' '}
            {formatDate(dataRefreshedDate)}
          </div>
          <LicensingContact
            // licensingAuthorityId={licensingAuthorityId}
            // licensingAuthority={licensingAuthority}
          />
        </ConnectedAsync>
      </div>
    );
  }
}

DetailsPage.propTypes = {
  /** The details of a record */
  details: T.object.isRequired,
  /** Gets the details of a record based off an id */
  getDetails: T.func.isRequired,
  /** Provided by withRouter - Gets id param from url */
  match: T.shape({ params: T.shape({ id: T.string }) }).isRequired,
};

export const mapStateToProps = createStructuredSelector({
  details: selectors.getDetails(),
});

export const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(actions.getDetails(id)),
});

const usingRouter = withRouter(DetailsPage);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);

export default usingRedux;
