import React from 'react';
import * as T from 'prop-types';
import { reduxForm } from 'redux-form';
import { FieldRadioButtons, Button, ConnectedAsync } from 'state-template';

import Modal from 'components/Modal';

export const ExportModal = ({ handleSubmit, onDismiss }) => (
  <Modal
    isOpen
    onDismiss={onDismiss}
    id={'export-modal'}
    title={'Export'}
  >
    <ConnectedAsync>
      <form onSubmit={handleSubmit} className={'row'}>
        <FieldRadioButtons
          name={'data'}
          label={'Data to Export'}
          aria-labelledby={'data--label'}
          options={[
            { value: 'visible', label: 'Only Visible Data *' },
            { value: 'all', label: 'All Data' },
          ]}
          className={'col-sm-6'}
        />
        <FieldRadioButtons
          name={'type'}
          label={'Export Type'}
          aria-labelledby={'data--label'}
          options={[
            { value: 'csv', label: 'CSV/Excel' },
          ]}
          className={'col-sm-6'}
        />

        <div className={'col-12'}>
          <strong>* </strong>
        Only columns in the
          <strong> Visible Columns </strong>
        section of the
          <strong> Customize Results </strong>
        dropdown will be used.
        </div>

        <div className={'ExportModal__footer col-12'}>
          <Button onClick={onDismiss} text={'Cancel'} variant={'default'} />
          <Button type={'submit'} text={'Submit'} variant={'primary'} />
        </div>
      </form>
    </ConnectedAsync>
  </Modal>
);

ExportModal.propTypes = {
  /** Provided by redux-form, submits the form to redux-form using the `onSubmit` prop */
  handleSubmit: T.func.isRequired,
  /** Dismisses the modal */
  onDismiss: T.func.isRequired,
};

const usingForm = reduxForm({
  form: 'export',
  initialValues: { data: 'visible', type: 'csv' },
})(ExportModal);

export default usingForm;
