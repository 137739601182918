import React from 'react';
import { A } from 'state-template';


export const LicensingContact = () => {
  //const { licensingAuthorityId, licensingAuthority } = props; // eslint-disable-line react/prop-types

  // let LicensedComplaintLink;
  // let LicenseContactLink;

  // if (licensingAuthorityId === 'BCC') {
  //   LicensedComplaintLink = 'https://bcc.ca.gov/consumers/file_complaint.html';
  //   LicenseContactLink = 'https://bcc.ca.gov/';
  // } else if (licensingAuthorityId === 'CCL') {
  //   LicensedComplaintLink = 'https://calcannabis.cdfa.ca.gov/';
  //   LicenseContactLink = 'https://calcannabis.cdfa.ca.gov/';
  // } else {
  //   LicensedComplaintLink = 'https://www.cdph.ca.gov/Programs/CEH/DFDCS/MCSB/Pages/Complaint.aspx';
  //   LicenseContactLink = 'https://www.cdph.ca.gov/Programs/CEH/DFDCS/MCSB/Pages/MCSB.aspx';
  // }

  return (
    <>
      <div className={'LicensingContact'}>
        <h2 className={'LicensingContact__title'}>Have an Issue?</h2>
      </div>
      <section>
        <div className={'font-weight-bold LicensingContact__links'}>
          <p>
            <A to={{ pathname: '/complaints/unlicensed' }} data-test={'complaint-link'}>File a Complaint for this License</A>
          </p>
          {/* <p>
            <A href={LicenseContactLink} data-test={'contact-link'}>{`Click here For More Information on ${licensingAuthority}`}</A>
          </p> */}
        </div>
      </section>
    </>
  );
};

export default LicensingContact;
