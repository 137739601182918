import React from 'react';
import * as T from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { A } from 'state-template';
import routes from 'routes';
import * as actions from 'redux/actions';

import { PanelButton } from 'components/Panel';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import SearchBoxForm from 'components/SearchBoxForm/SearchBoxForm';
import AdvancedSearchForm from './AdvancedSearchForm';

export class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { advancedSearchOpen: false };
  }

  toggleAdvancedSearch = () => {
    this.setState((state) => ({ advancedSearchOpen: !state.advancedSearchOpen }));
  }

  onSearch = (values) => {
    const { history, updateFilters, resetForm } = this.props;
    const search = `?${qs.stringify(values)}`;
    updateFilters({});
    resetForm('filters');
    history.push({ pathname: routes.results.path, search });
  };

  getSearchBoxValue = () => {
    const { location } = this.props;
    const values = qs.parse(location.search);
    const searchQuery = Object.values(values).join(' ');
    return { searchQuery };
  }

  render() {
    const { advancedSearchOpen } = this.state;
    return (
      <div className={'HomePage'}>
        <h1>Cannabis Unified License Search</h1>
        <SearchBoxForm
          onSubmit={this.onSearch}
          initialValues={this.getSearchBoxValue()}
          data-test={'search-box'}
        />
        <div className={'HomePage__actions'}>
          <A text={'Find Retailers Near Me'} to={routes.retailersMap.path} />
          <span />
          <PanelButton
            // className={'float-right'}
            text={'Advanced Search'}
            onClick={this.toggleAdvancedSearch}
            isOpen={advancedSearchOpen}
            data-test={'toggle-advanced-search'}
            aria-expanded={advancedSearchOpen}
          />
        </div>
        {advancedSearchOpen && (
        <AdvancedSearchForm
          onSubmit={this.onSearch}
          data-test={'advanced-search-form'}
        />
        )}

      </div>
    );
  }
}

HomePage.propTypes = {
  /** Provided by withRouter - Browser history */
  history: T.shape({ push: T.func }).isRequired,
  /** Provided by withRouter - Browser URL Location */
  location: T.shape({ search: T.string }).isRequired,
  /** Dispatches a request to redux for resetting a form */
  resetForm: T.func.isRequired,
  /** Applies filters to the results of the search */
  updateFilters: T.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  updateFilters: (filters) => dispatch(actions.updateFilters(filters)),
  resetForm: (form) => dispatch(reset(form)),
});

const usingRouter = withRouter(HomePage);
const usingRedux = connect(null, mapDispatchToProps)(usingRouter);

export default usingRedux;
