import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as T from 'prop-types';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import {
  Button, FieldInput, FieldRadioButtons, FieldFile, FieldSelect
} from 'state-template';
import { createStructuredSelector } from 'reselect';

import Captcha from 'components/Captcha';
import schema from './complaintsSchema';

export const ComplaintDetails = ({
  handleSubmit,
  onCancel,
  onPrevious,
  // licenseAuthority,
  pristine,
  change,
  complaintSource,
  isFiledAnonymously,
}) => {
  const [reCaptchaToken, setreCaptchaToken] = useState('');
  const complaintSourceStyle = complaintSource === 'Other' ? 'col-sm-3' : 'col-sm-6';
  const recaptchaRef = React.createRef();

  const verifyCaptcha = (e) => {
    e.preventDefault();
    recaptchaRef.current.execute(recaptchaRef);
    if (reCaptchaToken) {
      handleSubmit();
    }
  };

  const onSubmit = (captcha) => {
    setreCaptchaToken(captcha);
    change(schema.reCaptchaToken.name, captcha);
    handleSubmit();
  };

  return (
    <form onSubmit={verifyCaptcha}>
      {/* <div className={'UnlicensedComplaintPage__section'}>
        <div className={'row'}>
          <h3 className={'col-12'}>License Authority</h3>
          <h4 className={'col-12'}>{licenseAuthority.name}</h4>
          <div className={'col-12 UnlicensedComplaintPage__authority-desc'}>{licenseAuthority.description}</div>
          <div className={'col-md-4'}>
            <strong>Website: </strong>
            <A href={licenseAuthority.website} text={licenseAuthority.name} />
          </div>
          <div className={'col-md-4'}>
            <strong>Email: </strong>
            {licenseAuthority.email}
          </div>
          <div className={'col-md-4'}>
            <strong>Phone: </strong>
            {licenseAuthority.phone}
          </div>
        </div>
      </div> */}

      <div className={'UnlicensedComplaintPage__section'}>
        <div className={'row'}>
          <h3 className={'col-12'}>Submitter Information</h3>
          <FieldRadioButtons {...schema.fileAnonymously} className={'col-12'} inline required />
          { !isFiledAnonymously && (
            <>
              <FieldInput {...schema.firstName} className={'col-sm-6'} />
              <FieldInput {...schema.lastName} className={'col-sm-6'} />
              <FieldInput {...schema.phone} className={'col-sm-6'} />
              <FieldInput {...schema.email} className={'col-sm-6'} />
            </>
          )}
        </div>
      </div>

      <div className={'UnlicensedComplaintPage__section'}>
        <div className={'row'}>
          <h3 className={'col-12'}>Complaint Information</h3>
          <FieldInput {...schema.businessName} className={'col-sm-6'} />
          <FieldInput {...schema.businessLocation} className={'col-sm-6'} />
          <FieldInput {...schema.complaintDetails} className={'col-12'} required />
          <FieldFile {...schema.supportingDocuments} className={'col-sm-6'} />
          <FieldSelect {...schema.complaintSource} className={complaintSourceStyle} required />
          {complaintSource === 'Other'
            && <FieldInput {...schema.complaintSourceDetail} className={'col-sm-3'} />}
        </div>
      </div>

      <div className={'UnlicensedComplaintPage__actions'}>
        <Button text={'Cancel'} variant={'default'} onClick={onCancel} />

        <div className={'UnlicensedComplaintPage__actions-group'}>
          <Button text={'Back'} variant={'default'} onClick={onPrevious} />
          <Button type={'submit'} text={'Submit'} variant={'primary'} disabled={pristine} />
          <Field
            {...schema.reCaptchaToken}
            data-test={'captcha'}
            component={Captcha}
            recaptchaRef={recaptchaRef}
            onExpired={() => setreCaptchaToken('')}
            onChange={(captcha) => onSubmit(captcha)}
          />
        </div>
      </div>
    </form>
  );
};

ComplaintDetails.propTypes = {
  /** Provided by redux-form, submits the form to redux-form using the `onSubmit` prop */
  handleSubmit: T.func.isRequired,
  /** Cancels the wizard */
  onCancel: T.func.isRequired,
  /** Information about the licensing authority */
  licenseAuthority: T.shape({
    name: T.string,
    website: T.node,
    email: T.string,
    phone: T.string,
    description: T.string,
  }).isRequired,
  /** Provided by redux-form, determines if form has been changed */
  pristine: T.bool.isRequired,
  /** Sets the previous page */
  onPrevious: T.func.isRequired,
  /** Provided by redux-form, changes a fields value */
  change: T.func.isRequired,
  /** Source of complaint selected by the user */
  complaintSource: T.string,
  /** Whether the user is filing anonymously or not */
  isFiledAnonymously: T.bool,
};

ComplaintDetails.defaultProps = {
  complaintSource: null,
  isFiledAnonymously: true,
};

const selector = formValueSelector('unlicensed-complaints');

export const mapStateToProps = createStructuredSelector({
  complaintSource: (state) => selector(state, schema.complaintSource.name),
  isFiledAnonymously: (state) => selector(state, schema.fileAnonymously.name),
});

const usingForm = reduxForm({
  form: 'unlicensed-complaints',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(ComplaintDetails);

const usingRedux = connect(mapStateToProps)(usingForm);
export default usingRedux;
