const mimeTypes = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'audio/wave',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
  'video/mp4',
  'application/pdf',
  'image/heic',
];

export default mimeTypes;
