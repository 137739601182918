import React from 'react';
import * as T from 'prop-types';
import { reduxForm } from 'redux-form';
import { FieldInput, Button } from 'state-template';

export const SearchBoxForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} className={'SearchBox'}>
    <FieldInput
      name={'searchQuery'}
      aria-label={'search'}
      placeholder={'Search by License Number, Name, etc...'}
      autoComplete={'off'}
    />

    <Button
      value={"Search"}
      iconProps={{ name: 'search-right', srOnly: 'Submit Search' }}
      className={'SearchBox__icon'}
      type={'submit'}
      onClick={handleSubmit}
    >
      Search
      </Button>
  </form>
);

SearchBoxForm.propTypes = {
  /** Provided by redux-form, submits the form to redux-form using the `onSubmit` prop */
  handleSubmit: T.func.isRequired,
};

const usingForm = reduxForm({
  form: 'search',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(SearchBoxForm);

export default usingForm;
