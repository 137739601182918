// Convert an angle from degrees to radians.
const degToRad = (deg) => deg * (Math.PI / 180);

// Calculate the great-circle distance between two points on the globe.
// https://en.wikipedia.org/wiki/Haversine_formula
// Be advised: the cryptic variable names here are mathematically relevant.
export const haversine = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8; // Earth's radius in miles.
  const dLat = degToRad(lat2 - lat1);
  const dLon = degToRad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

// Convert leaflet LatLng objects for our needs.
// https://leafletjs.com/reference-1.6.0.html#latlng
export const parsePoint = (point) => ({
  latitude: point.lat,
  longitude: point.lng,
});

// Extrapolate a map's bounding-box from a leaflet LatLngBounds object.
// https://leafletjs.com/reference-1.6.0.html#latlngbounds
export const parseBounds = (bounds) => ({
  minLatitude: bounds._southWest.lat,
  maxLatitude: bounds._northEast.lat,
  minLongitude: bounds._southWest.lng,
  maxLongitude: bounds._northEast.lng,
});
