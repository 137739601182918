import React from 'react';
import * as T from 'prop-types';
import * as selectors from 'redux/selectors';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Button,
  FieldSelect,
  ConnectedAsync,
  FieldCheckboxes } from 'state-template';
import { MoveFocusInside } from 'react-focus-lock';

import schema from 'schema';
import Panel from 'components/Panel';
import FieldDateRange from 'components/FieldDateRange';

export const FiltersPanel = ({
  onClear,
  pristine,
  // eslint-disable-next-line react/prop-types
  handleSubmit,
  licenseTypes,
  licenseStatuses,
  licenseTerms,
  licenseDesignations,
  licenseAuthorities,
  clearFields,
  change,
}) => {
  const [microbusinessType] = licenseTypes.filter((x) => x.value.toLowerCase().includes('microbusiness'));

  const setMicrobusinessSearch = ([isMicrobusiness]) => {
    if (isMicrobusiness) {
      change(schema.licenseType.name, microbusinessType.value);
    } else {
      clearFields('filters', false, false, [schema.licenseType.name]);
      clearFields('filters', false, false, ['microbusiness']);
    }
  };

  const onLicenseTypeChange = (e) => {
    const { value } = e.target;
    if (value === microbusinessType.value) {
      change('microbusiness', [true]);
    } else {
      clearFields('filters', false, false, ['microbusiness']);
    }
  };

  return (
    <Panel title={'Filters'}>
      <MoveFocusInside>
        <ConnectedAsync>
          <form className={'row'} onSubmit={handleSubmit}>
            {/* <FieldInput {...schema.premiseCity} className={'col-sm-4'} /> */}
            {/* <FieldSelect {...schema.premiseCounty} options={counties} className={'col-sm-4'} /> */}
            {/* <FieldInput {...schema.premiseZipCode} className={'col-sm-4'} /> */}

            <FieldSelect {...schema.licenseStatus} options={licenseStatuses} className={'col-sm-4'} />
            <FieldSelect
              {...schema.licenseType}
              options={licenseTypes}
              className={'col-sm-4'}
              onChange={onLicenseTypeChange}
            />
            <FieldSelect {...schema.licenseDesignation} options={licenseDesignations} className={'col-sm-4'} />

            <FieldSelect {...schema.licenseTerm} options={licenseTerms} className={'col-sm-4'} />
            {/* <FieldSelect {...schema.licensingAuthority} options={licenseAuthorities} className={'col-sm-4'} /> */}
            <FieldCheckboxes
              name={'microbusiness'}
              label={'Microbusiness'}
              aria-labelledby={'microbusiness--label'}
              onChange={setMicrobusinessSearch}
              options={[{ value: true, label: 'Search only Microbusiness' }]}
              className={'col-sm-4'}
              normalize={(e) => e[0]}
            />

            <FieldDateRange {...schema.issueDate} className={'col-sm-6'} />
            <FieldDateRange {...schema.expirationDate} className={'col-sm-6'} />

            <div className={'FilterPanel__actions col-12'}>
              <Button text={'Clear'} variant={'default'} onClick={onClear} disabled={pristine} />
              <Button text={'Submit'} variant={'primary'} type={'submit'} />
            </div>
          </form>
        </ConnectedAsync>
      </MoveFocusInside>
    </Panel>
  );
};

FiltersPanel.propTypes = {
  /** Clears the form */
  onClear: T.func.isRequired,
  /** Provided by redux-form, determines if form has been changed */
  pristine: T.bool.isRequired,
  /** Provided by redux-form, changes a fields value */
  change: T.func.isRequired,
  /** Provided by redux-form, clears a fields value */
  clearFields: T.func.isRequired,
  /** An array of all counties */
  counties: T.array.isRequired,
  /** An array of all license types */
  licenseTypes: T.array.isRequired,
  /** An array of all license statuses */
  licenseStatuses: T.array.isRequired,
  /** An array of all license terms */
  licenseTerms: T.array.isRequired,
  /** An array of all license designations */
  licenseDesignations: T.array.isRequired,
  /** An array of all license designations */
  licenseAuthorities: T.array.isRequired,
};

const selector = formValueSelector('filters');

export const mapStateToProps = createStructuredSelector({
  counties: selectors.getCounties(),
  licenseTypes: selectors.getLicenseTypes(),
  licenseStatuses: selectors.getLicenseStatuses(),
  licenseTerms: selectors.getLicenseTerms(),
  licenseDesignations: selectors.getLicenseDesignations(),
  licenseAuthorities: selectors.getLicenseAuthoritiesDropdown(),
  microbusiness: (state) => selector(state, 'microbusiness'),
  licenseType: (state) => selector(state, 'licenseType'),
});

const usingForm = reduxForm({
  form: 'filters',
  destroyOnUnmount: false,
})(FiltersPanel);


const usingRedux = connect(mapStateToProps)(usingForm);
export default usingRedux;
