import React from 'react';
import '../../style/components/Branding.scss'
// import Navigation from 'components/Navigation/Navigation';

export class Branding extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: true, // default set to true
        };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.updatePredicate = this.updatePredicate.bind(this);  
    }

    toggleMenu(){
        this.setState({visible: !this.state.visible})
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
       window.removeEventListener("resize", this.updatePredicate);
    }
    
    updatePredicate() {
        if(window.innerWidth < 765){
            this.setState({ visible: false});
        }else{
            this.setState({visible: true});
        }
       
    }

    render() {
    return (
        <>
            <div className="branding">
                <div className="container">
                    <a href="https://cannabis.ca.gov/" className="logo" target={"_blank"} rel={"noopener noreferrer"}>
                        <svg version="1.1" x="0px" y="0px"
                            width="70px" height="70px" viewBox="0 0 70 70" style={{ enablebackground: 'new 0 0 70 70' }} xmlSpace="preserve">
                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n           .st0{fill:#3D6937;}\n       " }} />
                            <g>
                                <path className="st0" d="M35,69.3C16,69.2,0.7,53.8,0.8,34.9C0.8,16,16.2,0.6,35.1,0.7C54,0.8,69.3,16.1,69.2,35.1
               C69.2,54.2,53.8,69.2,35,69.3z M2.7,35c0,17.9,14.5,32.4,32.4,32.4c17.7,0,32.5-14.5,32.2-32.9C67.1,17,52.8,2.6,35,2.6
               C17,2.6,2.7,17.3,2.7,35z"/>
                                <path className="st0" d="M56.2,22c0.4,0.3,0.7,0.6,1,0.8c0.5,0.3,0.5,0.6,0.4,1.1c-0.5,1.6-1,3.3-1.6,4.9c-1.2,3.2-2.8,6.2-4.9,8.9
               c-0.2,0.3-0.5,0.5-0.9,0.5c-0.9,0.1-1.9,0.2-2.9,0.3c3.5-3.5,5.6-7.7,7.1-12.2c0,0-0.1-0.1-0.1-0.1c-0.3,0.2-0.6,0.4-0.8,0.6
               c-3.6,2.7-7,5.5-10.2,8.6c-2.3,2.3-4.2,4.9-5.8,7.8c0,0.1-0.1,0.2-0.1,0.4c0.2-0.1,0.3-0.1,0.4-0.1c2.5-1.2,5-2.2,7.8-2.6
               c2.3-0.3,4.6-0.6,6.9-0.7c2.8-0.1,5.5,0,8.3,0c0.5,0,0.6,0.2,0.8,0.6c0.2,0.7,0,1.1-0.5,1.5c-3.3,3-7,5.3-11.3,6.7
               c-0.5,0.1-1,0.4-1.4,0.3c-0.4-0.1-0.7-0.6-1.1-0.9c-0.3-0.3-0.7-0.6-1.1-1c4.2-0.6,7.7-2.5,11-4.9c0-0.1,0-0.1,0-0.2
               c-0.3,0-0.6,0-0.9,0c-2.9,0.2-5.8,0.3-8.6,0.6c-3.3,0.3-6.4,1.4-9.4,3c-0.1,0.1-0.2,0.1-0.4,0.3c0.2,0.1,0.3,0.1,0.4,0.1
               c3.9,0.7,6.7,2.9,8.9,6.1c1.1,1.6,1.9,3.4,2.5,5.2c0.1,0.3,0,0.5-0.2,0.8c-0.4,0.7-1,0.8-1.8,0.7c-3.4-0.4-6.6-1.4-9.3-3.5
               c-0.6-0.5-1.2-1.1-1.8-1.7c-0.1-0.1-0.1-0.3-0.1-0.4c0.3-0.7,0.7-1.4,1.1-2.1c2.2,3.2,5.4,4.4,9,5.1c0-0.1,0.1-0.1,0-0.1
               c-1.1-2.6-2.6-4.8-5-6.4c-1.5-1-3.3-1.6-5.1-1.7c-0.3,0-0.6,0-0.8,0.4c-1.3,4.5-4.2,7.5-8.6,9.1c-1.8,0.7-3.7,1.1-5.6,1.3
               c-0.3,0-0.5,0-0.7-0.3c0-0.1-0.1-0.1-0.1-0.2c-0.9-1-0.9-1-0.4-2.2c0.7-1.7,1.5-3.2,2.5-4.7c0.2-0.2,0.3-0.3,0.6-0.3
               c0.8,0.1,1.5,0.2,2.4,0.3c-1.3,1.4-2.2,3-3,4.8c2.5-0.5,4.8-1.2,6.8-2.7c1.6-1.2,2.8-2.8,3.5-4.8c-0.8,0.1-1.6,0.3-2.3,0.4
               c-4.5,0.6-8.9,0.3-13.1-1.4c-3.4-1.4-6.5-3.4-9.2-5.9c-0.2-0.2-0.3-0.4-0.2-0.8c0.1-0.4,0.1-1,0.4-1.3C9,39.9,9.5,40,10,40
               c2.4,0,4.7,0,7.1,0.1c0.1,0,0.3,0,0.4,0.1c0.8,0.8,1.6,1.5,2.4,2.3c-2.5-0.1-5-0.2-7.4-0.3c0,0.1,0,0.1,0,0.2
               c2.2,1.7,4.6,3.1,7.3,4c3.8,1.3,7.6,1.3,11.5,0.7c0,0,0-0.1,0-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-3.5-1.6-6.6-3.7-9.3-6.3
               c-2.9-2.7-4.9-6-6.5-9.6c-1-2.3-1.8-4.6-2.4-7.1c-0.1-0.3-0.1-0.6,0.3-0.8c0.2-0.1,0.4-0.3,0.6-0.5c0.4-0.4,0.7-0.4,1.1-0.1
               c4.5,3.1,8.8,6.5,12.8,10.3c0.2,0.1,0.3,0.4,0.3,0.6c0.2,1,0.3,2.1,0.5,3.1c0,0.1,0,0.3,0,0.3c-2-1.8-3.9-3.7-6-5.4
               c-2.1-1.8-4.2-3.4-6.4-5c1.2,3.9,2.9,7.5,5.5,10.7c3.2,3.8,7.2,6.4,11.8,8.4c0,0,0.1-0.1,0.1-0.1c-0.1-0.2-0.1-0.3-0.2-0.5
               c-1.6-3.4-2.5-7.1-3-10.8c-0.4-3.4-0.4-6.7-0.1-10.1c0.6-5.1,1.8-10,3.6-14.8c0.1-0.4,0.3-0.6,0.8-0.6c0.2,0,0.5,0,0.7,0
               c1,0,1,0,1.3,0.9c1.5,4.2,2.7,8.4,3.3,12.8c0.5,3.2,0.6,6.4,0.3,9.7c0,0.3-0.2,0.6-0.4,0.8c-0.8,0.9-1.6,1.7-2.4,2.7
               c1.4-7.9,0.1-15.4-2.3-22.8c-1.4,4-2.3,8.2-2.7,12.4c-0.6,6.3,0.3,12.4,2.8,18.4c0.4-0.7,0.7-1.3,1-1.9c2-3.5,4.5-6.6,7.5-9.3
               c3.9-3.5,8-6.8,12.3-9.7C55.9,22.2,56,22.1,56.2,22z"/>
                            </g>
                        </svg>

                    </a>
                    <a href="https://cannabis.ca.gov/" className="branding-logo" target={"_blank"} rel={"noopener noreferrer"}>
                        <div className="logo-text">
                            <span className="logo-dept">Department of</span>
                            <span className="logo-dept">Cannabis Control</span>
                            <span className="logo-state">CALIFORNIA</span>
                        </div>
                    </a>
                    {/* Legacy Navigation  */}
                    {/* <div className="mobile-icons">
                        <div className="mobile-menu">

                            <button onClick={this.toggleMenu} className="menu-btn toggle-menu">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <div className="menu-btn-text">Menu</div>
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
           {/* {this.state.visible ? <Navigation /> : ''} */}
        </>
    
    );
    }
}

export default Branding;