import React from 'react';
import * as T from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button, FieldInput, FieldSelect, ConnectedAsync } from 'state-template';
import * as selectors from 'redux/selectors';
import { connect } from 'react-redux';

import schema from 'schema';
import Panel from 'components/Panel';
import { minLength3 } from 'utils/validate';
import { createStructuredSelector } from 'reselect';

export const AdvancedSearchForm = ({ handleSubmit, reset, pristine, counties, licenseTypes, licenseStatuses, licenseTerms }) => (
  <Panel title={'Enter One or More Criteria'} className={'AdvSearch__panel'}>
    <ConnectedAsync>
      <form onSubmit={handleSubmit} className={'AdvSearch row'}>
        <FieldInput {...schema.licenseNumber} validate={[minLength3]} className={'col-sm-6'} />
        <FieldSelect {...schema.licenseType} options={licenseTypes} className={'col-sm-6'} />

        <FieldSelect {...schema.licenseStatus} options={licenseStatuses} className={'col-sm-6'} />
        <FieldInput {...schema.businessOwnerName} validate={[minLength3]} className={'col-sm-6'} />

        <FieldInput {...schema.businessLegalName} validate={[minLength3]} className={'col-sm-6'} />
        <FieldInput {...schema.businessDbaName} validate={[minLength3]} className={'col-sm-6'} />

        {/* <FieldInput {...schema.premiseStreetAddress} validate={[minLength3]} className={'col-sm-6'} /> */}
        {/* <FieldInput {...schema.premiseCity} validate={[minLength3]} className={'col-sm-6'} /> */}

        {/* <FieldSelect {...schema.premiseCounty} options={counties} className={'col-sm-6'} /> */}
        {/* <FieldInput {...schema.premiseZipCode} validate={[minLength3]} className={'col-sm-6'} /> */}

        <FieldSelect {...schema.licenseTerm} options={licenseTerms} className={'col-sm-6'} />

        <div className={'AdvSearch__actions col-12'}>
          <Button text={'Clear'} variant={'default'} onClick={reset} disabled={pristine} />
          <Button text={'Submit'} variant={'primary'} type={'submit'} disabled={pristine} />
        </div>
      </form>
    </ConnectedAsync>
  </Panel>
);

AdvancedSearchForm.propTypes = {
  /** Provided by redux-form, submits the form to redux-form using the `onSubmit` prop */
  handleSubmit: T.func.isRequired,
  /** Provided by redux-form, resets the form the the initial state */
  reset: T.func.isRequired,
  /** Provided by redux-form, determines if the form has not been touched yet */
  pristine: T.bool.isRequired,
  /** Array of all counties */
  counties: T.array.isRequired,
  /** Array of all license types */
  licenseTypes: T.array.isRequired,
  /** Array of all license types */
  licenseStatuses: T.array.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  counties: selectors.getCounties(),
  licenseTypes: selectors.getLicenseTypes(),
  licenseStatuses: selectors.getLicenseStatuses(),
  licenseTerms: selectors.getLicenseTerms(),
});

const usingForm = reduxForm({ form: 'advanced-search' })(AdvancedSearchForm);
const usingRedux = connect(mapStateToProps)(usingForm);

export default usingRedux;
