import React from 'react';
import * as T from 'prop-types';
import { circleMarker } from 'components/LabeledMapMarker';

export const Listing = React.forwardRef((props, ref) => {
  const {
    highlightedLicenseId,
    retailer,
    index,
    onClickBusiness,
    onBusinessHover,
    resetBusinessHover,
  } = props;
  const {
    id,
    businessLegalName,
    businessDbaName,
    premiseCounty,
    premiseZipCode,
    premiseStreetAddress,
    licenseType,
    distanceFromCenter,
  } = retailer;
  const businessName = (businessDbaName !== process.env.REACT_APP_DATA_NOT_AVAILABLE) ? businessDbaName : businessLegalName;
  const address = ((premiseStreetAddress !== process.env.REACT_APP_DATA_NOT_AVAILABLE) && (premiseStreetAddress !== process.env.REACT_APP_NOT_PUBLISHED)) ? premiseStreetAddress : (`${premiseCounty}, ${premiseZipCode}`);
  const shade = (id === highlightedLicenseId) ? 'highlighted' : '';
  const svg = (id === highlightedLicenseId) ? circleMarker(`${index}`, '#b71134') : circleMarker(`${index}`, 'black');
  return (
    <div
      ref={ref}
      tabIndex={0}
      role={'button'}
      className={`d-flex flex-row RetailerListing ${shade}`}
      onClick={() => onClickBusiness(id)}
      onKeyDown={(e) => (e.key === 'Enter' ? onClickBusiness(id) : null)}
      onMouseEnter={() => onBusinessHover(id)}
      onFocus={() => onBusinessHover(id)}
      onBlur={() => resetBusinessHover()}
      onMouseLeave={() => resetBusinessHover()}
      key={index}
    >
      <div className={'icon-col'}>
        {svg}
      </div>
      <div className={'info-col'}>
        <span className={'Business'}>{businessName}</span>
        <span className={'Address'}>{address}</span>
        <span className={'Type'}>{licenseType}</span>
        <span className={'Distance'}>
          {Number.parseFloat(distanceFromCenter).toFixed(1)}
          {' '}
          miles
        </span>
      </div>
    </div>
  );
});

Listing.propTypes = {
  /** ID of focused or hovered marker */
  highlightedLicenseId: T.number,
  /** Retailer Object */
  retailer: T.shape({
    id: T.number.isRequired,
    premiseLatitude: T.number.isRequired,
    premiseLongitude: T.number.isRequired,
    businessLegalName: T.string.isRequired,
    businessDbaName: T.string.isRequired,
    premiseCounty: T.string.isRequired,
    premiseZipCode: T.string.isRequired,
    premiseStreetAddress: T.string.isRequired,
    licenseType: T.string.isRequired,
    distanceFromCenter: T.number.isRequired,
  }).isRequired,
  /** Index of the retailer */
  index: T.number.isRequired,
  /** marker click handler */
  onClickBusiness: T.func.isRequired,
  /** marker hover handler */
  onBusinessHover: T.func.isRequired,
  /** marker mouseout / blur handler */
  resetBusinessHover: T.func.isRequired,
};

Listing.defaultProps = {
  highlightedLicenseId: null,
};

export default Listing;
