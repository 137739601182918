import React from 'react';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';

export const circleMarker = (label, color) => (
  <svg width={'32'} height={'44'} viewBox={'0 0 35 45'} xmlns={'http://www.w3.org/2000/svg'}>
    <path d={'M17.5 2.746c-8.284 0-15 6.853-15 15.307 0 .963.098 1.902.265 2.816a15.413 15.413 0 002.262 5.684l.134.193 12.295 17.785 12.439-17.863.056-.08a15.422 15.422 0 002.343-6.112c.123-.791.206-1.597.206-2.423 0-8.454-6.716-15.307-15-15.307'} fill={color} />
    { label
      ? <text x={'50%'} y={'43%'} dominantBaseline={'middle'} textAnchor={'middle'} fill={'white'} fontWeight={'bold'} fontFamily={'sans-serif'}>{label}</text>
      : <circle cx={'17.5'} cy={'18'} r={'4'} fill={'white'} />}
  </svg>
);

export const leafletMarker = (label, color) => {
  const marker = circleMarker(label, color);
  const img = ReactDOMServer.renderToString(marker);
  const iconUrl = `data:image/svg+xml;base64,${btoa(img)}`;
  return new L.Icon({
    iconUrl,
    shadowUrl: '/img/svg-marker-shadow.png',
    shadowRetinaUrl: '/img/svg-marker-shadow@2x.png',
    iconAnchor: [17, 42],
    popupAnchor: [1, -32],
    shadowAnchor: [10, 12],
    shadowSize: [36, 16],
  });
};
