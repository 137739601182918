import React from 'react';
import * as T from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button, FieldRadioButtons } from 'state-template';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from 'redux/selectors';

const disclaimer = `All
 commercial cannabis activities in California require a license from one
 of the state's three cannabis licensing authorities. Selling cannabis
 goods without a state license is a violation of the state law. Enforcement
 of unlicensed commercial cannabis activity may occur at the state and/or
 local level.
`;

export const BuisnessType = ({
  handleSubmit,
  onCancel,
  pristine,
  businessLicenseTypes,
}) => (
  <div>
    <div className={'UnlicensedComplaintPage__disclaimer'}>{disclaimer}</div>

    <form onSubmit={handleSubmit} className={'row'}>
      <FieldRadioButtons
        required
        aria-label={'Business Type selection'}
        name={'licenseType'}
        label={'Choose a type of business that you want to file a complaint against'}
        options={businessLicenseTypes}
        className={'col-12'}
      />

      <div className={'UnlicensedComplaintPage__actions col-12'}>
        <Button text={'Cancel'} variant={'default'} onClick={onCancel} />
        <Button type={'submit'} text={'Next'} variant={'primary'} disabled={pristine} />
      </div>
    </form>
  </div>
);

BuisnessType.propTypes = {
  /** Provided by redux-form, submits the form to redux-form using the `onSubmit` prop */
  handleSubmit: T.func.isRequired,
  /** Cancels the wizard */
  onCancel: T.func.isRequired,
  /** Provided by redux-form, determines if form has been changed */
  pristine: T.bool.isRequired,
  /** Array of License Business Types */
  businessLicenseTypes: T.array.isRequired,
};

const usingForm = reduxForm({
  form: 'unlicensed-complaints',
  destroyOnUnmount: false,
})(BuisnessType);


export const mapStateToProps = createStructuredSelector({
  businessLicenseTypes: selectors.getlicenseBusinessTypes(),
});

export default connect(mapStateToProps)(usingForm);
