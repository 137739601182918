import React from 'react';
import * as T from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { A, Async } from 'state-template';
import { debounce } from 'underscore';

import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import { formatAutoSuggest } from 'utils/formatData';
import routes from 'routes';
import Map from './Map';
import LocationSearchForm from './LocationSearchForm/LocationSearchForm';
import MapMarker from './MapMarker';
import Listing from './Listing';

export const NotFound = () => (
  <div className={'not-found'}>
    <h2>No retailers found.</h2>
    <p>Try moving the map.</p>
  </div>
);

export const EmailLink = () => (
  <div className={'emailLink'}>
    {'Not finding what you\'re looking for? Email '}
    <strong><A href={'mailto: info@cannabis.ca.gov'} text={'info@cannabis.ca.gov'} /></strong>
  </div>
);

export class RetailersMapPage extends React.Component {
  constructor(props) {
    super(props);
    this.map = React.createRef();
    this.markerRefs = [];
    this.listRef = React.createRef();
    this.state = {
      bottom: false,
      highlightedLicenseId: null,
    };
  }

  componentDidMount = () => {
    const { resetAutoSuggest } = this.props;
    resetAutoSuggest();
  }

  onClickBusiness = (id) => {
    const { history, updateBreadcrumb } = this.props;
    const detailsPath = routes.details.path.replace(':id', id);
    updateBreadcrumb(routes.retailersMap.path);
    history.push(detailsPath);
  }

  onBusinessHover = (id) => {
    this.scrollToMarker(id);
    this.setState({ highlightedLicenseId: id });
  }

  resetBusinessHover = () => {
    this.setState({ highlightedLicenseId: null });
  }

  onSearch = (values) => {
    const { getLocation } = this.props;
    if (values.query) {
      getLocation(values);
    }
  }

  onSearchChange = (values) => {
    const { getLocationAutoSuggest, userGeolocation, resetAutoSuggest } = this.props;
    const userSearch = { ...values };
    if (userSearch.query) {
      userSearch.userLocation = `${userGeolocation.latitude},${userGeolocation.longitude}`;
      getLocationAutoSuggest(userSearch);
    } else {
      resetAutoSuggest();
    }
  }

  generateRetailerComponents = () => {
    const { retailers } = this.props;
    const { highlightedLicenseId } = this.state;

    const listings = [];
    const markers = [];
    retailers.forEach((retailer, i) => {
      const { id } = retailer;
      const index = i + 1;
      const listing = (
        <Listing
          ref={(ref) => { this.markerRefs[id] = ref; }}
          key={id}
          index={index}
          retailer={retailer}
          highlightedLicenseId={highlightedLicenseId}
          onClickBusiness={this.onClickBusiness}
          onBusinessHover={this.onBusinessHover}
          resetBusinessHover={this.resetBusinessHover}
        />
      );
      const marker = (
        <MapMarker
          key={id}
          index={index}
          retailer={retailer}
          highlightedLicenseId={highlightedLicenseId}
          onClickBusiness={this.onClickBusiness}
          onBusinessHover={this.onBusinessHover}
          resetBusinessHover={this.resetBusinessHover}
        />
      );
      listings.push(listing);
      markers.push(marker);
    });
    return { listings, markers };
  }

  scrollToMarker = (id) => {
    this.markerRefs[id].scrollIntoView({ block: 'nearest', inline: 'start' });
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop + 100 > e.target.clientHeight;
    this.setState({ bottom });
  }

  render() {
    const { retailers, locationAutoSuggest, isRetailersLoading } = this.props;
    const { bottom } = this.state;
    const formattedAutoSuggest = formatAutoSuggest(locationAutoSuggest);
    const { listings, markers } = this.generateRetailerComponents();

    return (
      <>
        <div className={'RetailersMapPage'}>
          <h1>Retailers Near Me</h1>
          <div className={'container page-container'}>
            <div className={'row'}>
              <div className={'col-xs-12 col-lg-8 map-column'}>
                <LocationSearchForm
                  onChange={debounce(this.onSearchChange, 200)}
                  onSubmit={this.onSearch}
                  data-test={'search-box'}
                  placeholder={'Search by Address'}
                  name={'query'}
                  autoSuggest={formattedAutoSuggest}
                />
                <Map
                  markers={markers}
                />
              </div>
              <div className={'col-xs-12 col-lg-4 remove-x-pad'}>
                <ul ref={this.listRef} className={'listings'} onScroll={this.handleScroll} aria-live={'assertive'} aria-atomic={false}>
                  <Async isLoading={isRetailersLoading}>
                    { (retailers.length === 0) ? <NotFound /> : listings }
                  </Async>
                  { window.innerWidth <= 992 && (bottom || listings.length < 1) && (
                    <EmailLink />
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        { window.innerWidth > 992 && (
          <EmailLink />
        )}
      </>
    );
  }
}

RetailersMapPage.propTypes = {
  /** Array of retails within bounds */
  retailers: T.array.isRequired,
  /** current user location */
  userGeolocation: T.object,
  /** Provided by withRouter - Browser history */
  history: T.shape({ push: T.func }).isRequired,
  /** Used to get Autosuggestions */
  getLocationAutoSuggest: T.func.isRequired,
  /** Array of autoSuggest locations returned from an API */
  locationAutoSuggest: T.array,
  /** Used to reset the autosuggest list */
  resetAutoSuggest: T.func.isRequired,
  /** Get geocoordinates from Bing API */
  getLocation: T.func.isRequired,
  /** determines whether or not the spinner should be shown */
  isRetailersLoading: T.bool.isRequired,
  /** Updates breadcrumb with the path it took to get to details */
  updateBreadcrumb: T.func.isRequired,
};

RetailersMapPage.defaultProps = {
  locationAutoSuggest: null,
  userGeolocation: null,
};

export const mapStateToProps = createStructuredSelector({
  retailers: selectors.getRetailers(),
  geolocation: selectors.getGeolocation(),
  locationAutoSuggest: selectors.getLocationAutoSuggest(),
  userGeolocation: selectors.getUserGeolocation(),
  isRetailersLoading: selectors.getRetailersLoading(),
});

export const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumb: (value) => dispatch(actions.updateBreadcrumbLocation(value)),
  getLocationAutoSuggest: (value) => dispatch(actions.getLocationAutoSuggest(value)),
  getLocation: (value) => dispatch(actions.getLocation(value)),
  resetAutoSuggest: () => dispatch(actions.getLocationAutoSuggestSuccess([])),
});

const usingRouter = withRouter(RetailersMapPage);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);

export default usingRedux;
