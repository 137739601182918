import { createSelector } from 'reselect';

import { haversine } from 'utils/coordinates';
import { formatDropdowns, formatlicenseBusinessType, sortLicenseType } from 'utils/formatData';

export const selectDomain = (state) => state.reducer;
export const selectSearch = (state) => selectDomain(state).search;
export const selectFilters = (state) => selectDomain(state).filters;
export const selectGeolocation = (state) => selectDomain(state).geolocation;
export const selectCounties = (state) => selectDomain(state).counties;
export const selectLicenseTypes = (state) => selectDomain(state).licenseTypes;
export const selectLicenseStatuses = (state) => selectDomain(state).licenseStatuses;
export const selectLicenseTerms = (state) => selectDomain(state).licenseTerms;
export const selectLicenseDesignations = (state) => selectDomain(state).licenseDesignations;
export const selectLicenseAuthorities = (state) => selectDomain(state).licenseAuthorities;
export const selectLicenseBusinessTypes = (state) => selectDomain(state).licenseBusinessTypes;
export const selectTableLoading = (state) => selectDomain(state).tableLoading;
export const selectResults = (state) => selectDomain(state).results;
export const selectResultsMetadata = (state) => selectDomain(state).resultsMetadata;
export const selectDetails = (state) => selectDomain(state).details;
export const selectRetailers = (state) => selectDomain(state).retailers;
export const selectRetailersLoading = (state) => selectDomain(state).retailersLoading;
export const selectUnlicensedComplaintResponse = (state) => selectDomain(state).unlicensedComplaintResponse;
export const selectLocationAutoSuggest = (state) => selectDomain(state).locationAutoSuggest;
export const selectSearchLocationCoords = (state) => selectDomain(state).searchLocationCoords;
export const selectUserGeolocation = (state) => selectDomain(state).userGeolocation;
export const selectBreadcrumbLocation = (state) => selectDomain(state).breadcrumbLocation;
export const SelectUserLocationInit = (state) => selectDomain(state).userLocationInit;

export const getSearch = () => createSelector(
  selectSearch,
  (search) => search,
);

export const getFilters = () => createSelector(
  selectFilters,
  (filters) => filters,
);

export const getGeolocation = () => createSelector(
  selectGeolocation,
  (geolocation) => geolocation,
);

export const getLicenseTypes = () => createSelector(
  selectLicenseTypes,
  (licenseTypes) => [...licenseTypes, {
    id: 'Manufacturer - All',
    value: 'Manufacturer',
    label: 'Manufacturer - All',
  },
  {
    id: 'Cultivation - All',
    value: 'Cultivation',
    label: 'Cultivation - All',
  }].sort((a, b) => sortLicenseType(a, b)),
);

export const getLicenseStatuses = () => createSelector(
  selectLicenseStatuses,
  (licenseStatuses) => licenseStatuses,
);

export const getLicenseTerms = () => createSelector(
  selectLicenseTerms,
  (licenseTerms) => licenseTerms,
);

export const getLicenseDesignations = () => createSelector(
  selectLicenseDesignations,
  (licenseDesignations) => licenseDesignations,
);

export const getLicenseAuthoritiesDropdown = () => createSelector(
  selectLicenseAuthorities,
  (licenseAuthorities) => formatDropdowns(licenseAuthorities),
);

export const getLicenseAuthorities = () => createSelector(
  selectLicenseAuthorities,
  (licenseAuthorities) => licenseAuthorities,
);

export const getlicenseBusinessTypes = () => createSelector(
  selectLicenseBusinessTypes,
  (licenseBusinessTypes) => formatlicenseBusinessType(licenseBusinessTypes),
);

export const getCounties = () => createSelector(
  selectCounties,
  (counties) => counties,
);

export const getTableLoading = () => createSelector(
  selectTableLoading,
  (tableLoading) => tableLoading,
);

export const getResults = () => createSelector(
  selectResults,
  (results) => results,
);

export const getResultsMetadata = () => createSelector(
  selectResultsMetadata,
  (resultsMetadata) => resultsMetadata,
);

export const getDetails = () => createSelector(
  selectDetails,
  (details) => details,
);

export const getRetailers = () => createSelector(
  [selectRetailers, selectGeolocation],
  (retailers, geolocation) => {
    const calculatedRetailers = retailers.map((retailer) => ({
      ...retailer,
      distanceFromCenter: haversine(
        retailer.premiseLatitude,
        retailer.premiseLongitude,
        geolocation.latitude,
        geolocation.longitude,
      ),
    }));
    return calculatedRetailers.sort((a, b) => (a.distanceFromCenter - b.distanceFromCenter));
  },
);

export const getRetailersLoading = () => createSelector(
  selectRetailersLoading,
  (retailersLoading) => retailersLoading,
);

export const getUnlicensedComplaintResponse = () => createSelector(
  selectUnlicensedComplaintResponse,
  (unlicensedComplaintResponse) => unlicensedComplaintResponse,
);

export const getLocationAutoSuggest = () => createSelector(
  selectLocationAutoSuggest,
  (locationAutoSuggest) => locationAutoSuggest,
);

export const getUserGeolocation = () => createSelector(
  selectUserGeolocation,
  (userGeolocation) => userGeolocation,
);

export const getBreadcrumbPrevLocation = () => createSelector(
  selectBreadcrumbLocation,
  (breadcrumbLocation) => breadcrumbLocation,
);

export const getUserLocationInit = () => createSelector(
  SelectUserLocationInit,
  (userLocationInit) => userLocationInit,
);
