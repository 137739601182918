import React from 'react';
import * as T from 'prop-types';
import { withField } from 'state-template';
import { isEmptyText } from 'state-template/dist/utils/validate';
import Downshift from 'downshift';

export const SearchBoxAutoComplete = ({
  onChange,
  name,
  onSearch,
  autoSuggest,
}) => {
  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: false,
          highlightedIndex: state.highlightedIndex,
        };
      case Downshift.stateChangeTypes.mouseUp:
        return {
          isOpen: false,
          highlightedIndex: state.highlightedIndex,
        };
      default:
        return changes;
    }
  };

  const onInputSearch = (event, highlightedIndex) => {
    if (event.key === 'Enter' && highlightedIndex !== null) {
      onSearch(autoSuggest[highlightedIndex].value);
    }
  };

  return (
    <Downshift
      onInputValueChange={(inputValue) => onChange(inputValue)}
      itemToString={(item) => (item ? item.value : '')}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getRootProps,
        getMenuProps,
        getLabelProps,
        isOpen,
        highlightedIndex,
        selectedItem,
      }) => (
        <div className={'search-box'}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label {...getLabelProps()}>Search for a Location</label>
          <div {...getRootProps({
            'aria-controls': 'downshift-0-label',
          }, { suppressRefError: true })}
          >
            <input
              {...getInputProps({
                onKeyDown: (e) => onInputSearch(e, highlightedIndex),
                name,
                className: 'field__input form-control',
              })}
            />
          </div>
          <ul
            {...getMenuProps({
              className: 'SearchBox__autoSuggest',
            })}
          >
            {isOpen && autoSuggest.length
              ? autoSuggest
                .map((item, index) => (
                  <li
                    {...getItemProps({
                      onClick: () => onSearch(item.value),
                      key: item.value,
                      index,
                      item,
                      style: {
                        backgroundColor:
                      highlightedIndex === index ? 'lightgray' : null,
                        fontWeight: selectedItem === item ? 'bold' : 'normal',
                      },
                    })}
                  >
                    <div className={'SearchBox__autoSuggest-item'}>{item.value}</div>
                  </li>
                ))
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
};

SearchBoxAutoComplete.propTypes = {
  /** Field Name */
  name: T.string.isRequired,
  /** Formatted array of autosuggestions */
  autoSuggest: T.array,
  /** Provided by with FieldHoc to control input */
  onChange: T.func.isRequired,
  /** Callback for selecting an option */
  onSearch: T.func,
};

SearchBoxAutoComplete.defaultProps = {
  autoSuggest: null,
  onSearch: null,
};

export default withField(isEmptyText)(SearchBoxAutoComplete);
