import { createAction } from 'redux-actions';

import * as C from './constants';

export const updateSearch = createAction(C.UPDATE_SEARCH);
export const updateFilters = createAction(C.UPDATE_FILTERS);

export const updateGeolocation = createAction(C.UPDATE_GEOLOCATION);
export const updateUserGeolocation = createAction(C.UPDATE_USER_GEOLOCATION);

export const getResults = createAction(C.GET_RESULTS);
export const getResultsSuccess = createAction(C.GET_RESULTS_SUCCESS);
export const getResultsMetadata = createAction(C.GET_RESULTS_METADATA);

export const getDropdowns = createAction(C.GET_DROPDOWNS);

export const getCountiesSuccess = createAction(C.GET_COUNTIES_SUCCESS);

export const getLicenseTypesSuccess = createAction(C.GET_LICENSE_TYPES_SUCCESS);

export const getLicenseStatusesSuccess = createAction(C.GET_LICENSE_STATUSES_SUCCESS);

export const getLicenseTermsSuccess = createAction(C.GET_LICENSE_TERMS_SUCCESS);

export const getLicenseDesignationsSuccess = createAction(C.GET_LICENSE_DESIGNATIONS_SUCCESS);

export const getLicenseAuthoritiesSuccess = createAction(C.GET_LICENSE_AUTHORITIES_SUCCESS);

export const getLicenseBusinessTypesSuccess = createAction(C.GET_LICENSE_BUSINESS_TYPES_SUCCESS);

export const getExport = createAction(C.GET_EXPORT);

export const getTableLoading = createAction(C.GET_TABLE_LOADING);

export const getDetails = createAction(C.GET_DETAILS);
export const getDetailsSuccess = createAction(C.GET_DETAILS_SUCCESS);

export const getRetailers = createAction(C.GET_RETAILERS);
export const getRetailersSuccess = createAction(C.GET_RETAILERS_SUCCESS);
export const updateReatilersLoading = createAction(C.UPDATE_RETAILERS_LOADING);

export const getModifiedResults = createAction(C.GET_MODIFIED_RESULTS);
export const getModifiedResultsSuccess = createAction(C.GET_MODIFIED_RESULTS_SUCCESS);

export const postUnlicensedComplaint = createAction(C.POST_UNLICENSED_COMPLAINT);
export const updateUnlicensedComplaint = createAction(C.UPDATE_UNLICENSED_COMPLAINT);
export const postUnlicensedComplaintSuccess = createAction(C.POST_UNLICENSED_COMPLAINT_SUCCESS);

export const getLocationAutoSuggest = createAction(C.GET_LOCATON_AUTO_SUGGEST);
export const getLocationAutoSuggestSuccess = createAction(C.GET_LOCATON_AUTO_SUGGEST_SUCCESS);

export const getLocation = createAction(C.GET_LOCATON);
export const getLocationSuccess = createAction(C.GET_LOCATON_SUCCESS);

export const updateBreadcrumbLocation = createAction(C.UPDATE_BREADCRUMB_LOCATION);

export const updateUserLocationInit = createAction(C.UPDATE_USER_LOCATION_INIT);
