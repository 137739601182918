import React from 'react';
import * as T from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

const { RECAPTCHA_SITE_KEY } = window.config;

const Captcha = ({ input, onExpired, size, recaptchaRef }) => (
  <div>
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={RECAPTCHA_SITE_KEY}
      size={size}
      onChange={input.onChange}
      onExpired={onExpired}
    />
  </div>
);

Captcha.propTypes = {
  input: T.shape({
    onChange: T.func,
  }).isRequired,
  onExpired: T.func,
  size: T.string,
  recaptchaRef: T.oneOfType([
    T.func,
    T.shape({ current: T.any }),
  ]).isRequired,
};

Captcha.defaultProps = {
  size: null,
  onExpired: null,
};

export default Captcha;
