import React from 'react';
import * as T from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import qs from 'query-string';
import {
  Button,
  Table,
  Async,
} from 'state-template';

import { PanelButton } from 'components/Panel';
import * as storage from 'utils/storage';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import routes from 'routes';
import schema from 'schema';

import SearchBoxForm from 'components/SearchBoxForm/SearchBoxForm';
import CustomizeResultsPanel from './CustomizeResultsPanel';
import ExportModal from './ExportModal';
import FiltersPanel from './FiltersPanel';

import {
  defaultColumnNames,
  getColumns,
} from './columns';

export class ResultsPage extends React.Component {
  constructor(props) {
    super(props);
    const columnNames = this.getDefaultColumnNames();

    this.state = {
      pageSize: 10,
      customizeResultsOpen: false,
      filtersOpen: false,
      exportOpen: false,
      columns: getColumns(columnNames),
      focusedColumn: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.search) {
      this.loadResults();
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.search !== prevProps.location.search) {
      this.loadResults();
    }
  }

  componentWillUnmount() {
    document.title = 'Cannabis Unified License Search';
  }

  loadResults = () => {
    const { getResults, location, filters, resultsMetadata } = this.props;
    const { currentPage, pageSize, sort } = resultsMetadata;
    const { newSearch } = this.state;
    const values = qs.parse(location.search);
    let newSearchParams = {};

    if (!newSearch) {
      newSearchParams = {
        ...filters,
        pageNumber: currentPage,
      };
    }

    if (sort && !newSearch) {
      newSearchParams.sortOrder = `${sort.dataField} ${sort.order}`;
    }

    const searchParams = {
      ...values,
      ...newSearchParams,
      pageSize,
    };

    this.setState({ newSearch: false });
    getResults(searchParams);
  }

  getSearchBoxValue = () => {
    const { location } = this.props;
    const values = qs.parse(location.search);
    const searchQuery = Object.values(values).join(' ');
    return { searchQuery };
  }

  getDefaultColumnNames = () => storage.get('savedColumnNames') || defaultColumnNames;


  toggleSection = (section) => {
    this.setState((state) => ({
      ...state,
      customizeResultsOpen: false,
      filtersOpen: false,
      exportOpen: false,
      [section]: !state[section],
    }));
  }

  toggleCustomizeResults = () => {
    this.toggleSection('customizeResultsOpen');
  }

  toggleFilters = () => {
    this.toggleSection('filtersOpen');
  }

  toggleExport = () => {
    this.toggleSection('exportOpen');
  }

  onSearch = (values) => {
    const { history } = this.props;
    const search = qs.stringify(values);
    this.resetFilters();
    this.setState({ newSearch: true });
    history.push({ pathname: routes.results.path, search });
  }

  onFilter = (filters) => {
    const { updateFilters, getResults, location } = this.props;
    const { pageSize } = this.state;

    const searchValues = qs.parse(location.search);

    const searchParams = {
      ...searchValues,
      ...filters,
      pageSize,
    };

    updateFilters(filters);
    getResults(searchParams);
  }

  resetFilters = () => {
    const { resetForm, updateFilters } = this.props;
    resetForm('filters');
    updateFilters({});
  }

  onClearFilters = () => {
    const { resetForm } = this.props;
    resetForm('filters');
    this.onFilter({});
  }

  onExport = (values) => {
    const { columns } = this.state;
    const { location, filters, getExport } = this.props;
    const { data } = values;
    const { FUNC_API } = window.config;

    const searchValues = qs.parse(location.search);

    const exportParams = {
      ...searchValues,
      ...filters,
      ...values,
      fields: (data === 'visible') ? columns.map((column) => column.dataField) : null,
    };

    const exportUrl = `${FUNC_API}/api/ExportAll?${qs.stringify(exportParams)}`;
    getExport(exportUrl);
  }

  onClickRow = (event, row) => {
    const { history, updateBreadcrumb } = this.props;
    const detailsPath = routes.details.path.replace(':id', row.id);
    updateBreadcrumb(routes.results.path);
    history.push({ pathname: detailsPath });
  }

  onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    const { getResults, location, filters, resultsMetadata } = this.props;
    const { sort } = resultsMetadata;
    const { pageSize } = this.state;
    const searchValues = qs.parse(location.search);
    // PROBLEM: onPageSize change Issue
    // w/o fix, if on page 2 with a page size of 25, results 25-50 will be shown.
    // If page size changes to 10, the second page of results (11-20) will be retrieved.
    // Causing users to go through results they've already been through
    //
    // TEMP FIX: reset to page 1 on page size change.
    //
    // React-Bootstrap-table 2 issue: wrong type passed on PageSize Change to onTableChange callback...
    // determining pageSizeChange based on state.

    const pageSizeChanged = pageSize !== sizePerPage;

    this.setState({
      pageSize: sizePerPage,
    });

    const searchParams = {
      ...searchValues,
      ...filters,
      pageNumber: pageSizeChanged ? 1 : page,
      pageSize: sizePerPage,
    };

    if (sortField) {
      searchParams.sortOrder = `${sortField} ${sortOrder}`;
    } else if (sort) {
      searchParams.sortOrder = `${sort.dataField} ${sort.order}`;
    }

    getResults(searchParams);
  }

  onColumnChange = (columns) => {
    this.setState({ columns });
  }

  onFocusRow = (event) => {
    this.setState({ focusedColumn: event.target.id });
  }

  onRowTabControl = (e) => {
    const { results } = this.props;

    // If on the first row and the user hits shift+tab, reset focus
    if ((e.key === 'Tab' && e.shiftKey)
      && (e.target.id === results[0].licenseNumber)) {
      this.resetFocusedRow();

    // If on the last row and the user hits tab, reset focus
    } else if ((e.key === 'Tab')
      && (e.target.id === results[results.length - 1].licenseNumber)) {
      this.resetFocusedRow();
    }
  }

  resetFocusedRow = () => {
    this.setState({ focusedColumn: null });
  }

  rowStyle = (row) => {
    const { focusedColumn } = this.state;

    if (focusedColumn === row.licenseNumber) {
      return 'focused-row';
    }
    return '';
  }

  render() {
    const { results, filters, resultsMetadata, isTableLoading } = this.props;
    const { currentPage, pageSize, totalCount } = resultsMetadata;
    const {
      customizeResultsOpen,
      filtersOpen,
      exportOpen,
      columns,
    } = this.state;

    document.title = `${totalCount} Results found for ${this.getSearchBoxValue().searchQuery}`;

    return (
      <>
        <h1>License Search</h1>
        <div className={'ResultsPage__search'}>
          <div
            className={'search-box'}
          >
            <SearchBoxForm
              onSubmit={this.onSearch}
              initialValues={this.getSearchBoxValue()}
              data-test={'search-box'}
              placeholder={'Search by License Number, Name, Address, etc...'}
              name={'searchQuery'}
            />
          </div>
          <div className={'ResultsPage__search-controls'}>
            <Button
              data-test={'toggle-export'}
              text={'Export'}
              variant={'standout'}
              onClick={this.toggleExport}
              className={'export-button'}
              disabled={!results.length}
            />
            <PanelButton
              data-test={'toggle-customize-results'}
              text={'Customize Results'}
              onClick={this.toggleCustomizeResults}
              isOpen={customizeResultsOpen}
              aria-expanded={customizeResultsOpen}
            />
            <PanelButton
              data-test={'toggle-filters'}
              text={`Filters (${Object.keys(filters).length})`}
              onClick={this.toggleFilters}
              isOpen={filtersOpen}
              aria-expanded={filtersOpen}
            />
          </div>

          {customizeResultsOpen && (
            <CustomizeResultsPanel
              onColumnChange={this.onColumnChange}
              data-test={'customize-results-panel'}
            />
          )}

          {filtersOpen && (
          <FiltersPanel
            data-test={'filters-panel'}
            onSubmit={this.onFilter}
            onClear={this.onClearFilters}
          />
          )}
        </div>
        <div>
          {`Total Results Found: ${totalCount}`}
        </div>

        <Async isLoading={isTableLoading}>
          {columns.length >= 1 && (
          <Table
            remote
            data-test={'results-table'}
            hideSearch
            page={currentPage}
            sizePerPage={pageSize}
            totalSize={totalCount}
            onTableChange={this.onTableChange}
            keyField={schema.licenseNumber.name}
            data={results}
            columns={columns}
            rowClasses={this.rowStyle}
            rowEvents={{
              onClick: this.onClickRow,
              onFocus: this.onFocusRow,
              onMouseLeave: this.resetFocusedRow,
              onMouseEnter: this.resetFocusedRow,
              onKeyDown: this.onRowTabControl,
            }}
          />
          )}
        </Async>

        {exportOpen && (
          <>
            <ExportModal
              data-test={'export-modal'}
              onSubmit={this.onExport}
              onDismiss={this.toggleExport}
            />
          </>
        )}
      </>
    );
  }
}

ResultsPage.propTypes = {
  /** Results of search to display */
  results: T.array.isRequired,
  /** Results of search to display */
  filters: T.object.isRequired,
  /** Applies filters to the results of the search */
  updateFilters: T.func.isRequired,
  /** Requests getting the results of the search */
  getResults: T.func.isRequired,
  /** Provided by withRouter - Browser history */
  history: T.shape({ push: T.func }).isRequired,
  /** Provided by withRouter - Browser URL Location */
  location: T.shape({ search: T.string }).isRequired,
  /** Dispatches a request to redux for resetting a form */
  resetForm: T.func.isRequired,
  /** Makes the request for the CSV export */
  getExport: T.func.isRequired,
  /** Indicates whether the table should be loading or not */
  isTableLoading: T.bool.isRequired,
  /** Metadata detailing the state information about the table */
  resultsMetadata: T.shape({
    currentPage: T.number.isRequired,
    pageSize: T.number.isRequired,
    totalCount: T.number.isRequired,
    sort: T.shape({
      dataField: T.string,
      order: T.string,
    }),
  }).isRequired,
  /** Updates breadcrumb with the path it took to get to details */
  updateBreadcrumb: T.func.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  results: selectors.getResults(),
  resultsMetadata: selectors.getResultsMetadata(),
  filters: selectors.getFilters(),
  isTableLoading: selectors.getTableLoading(),
});

export const mapDispatchToProps = (dispatch) => ({
  getExport: (url) => dispatch(actions.getExport(url)),
  updateFilters: (filters) => dispatch(actions.updateFilters(filters)),
  getResults: (values) => dispatch(actions.getResults(values)),
  resetForm: (form) => dispatch(reset(form)),
  updateBreadcrumb: (value) => dispatch(actions.updateBreadcrumbLocation(value)),
});

const usingRouter = withRouter(ResultsPage);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);

export default usingRedux;
