import { handleActions } from 'redux-actions';

import { formatRecord, formatDropdowns, formatCountiesDropdown } from 'utils/formatData';

import * as C from './constants';

export const initialState = {
  search: {},
  counties: [],
  licenseTypes: [],
  licenseStatuses: [],
  licenseTerms: [],
  licenseDesignations: [],
  licenseBusinessTypes: [],
  licenseAuthorities: [],
  filters: {},
  results: [],
  details: {},
  resultsMetadata: {
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
  },
  retailers: [],
  retailersLoading: false,
  geolocation: {
    latitude: '38.5816',
    longitude: '-121.4944',
    zoom: 11,
  },
  userGeolocation: {
    latitude: '38.5816',
    longitude: '-121.4944',
  },
  unlicensedComplaintResponse: {
    submitted: false,
  },
  tableLoading: false,
  locationAutoSuggest: [],
  searchLocationCoords: [],
  breadcrumbLocation: '',
  userLocationInit: false,
};

const updateSearch = (state, action) => ({
  ...state,
  search: action.payload,
});

const updateFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

const updateGeolocation = (state, action) => ({
  ...state,
  geolocation: action.payload,
});

const getLicenseTypesSuccess = (state, action) => ({
  ...state,
  licenseTypes: formatDropdowns(action.payload),
});

const getLicenseStatusesSuccess = (state, action) => ({
  ...state,
  licenseStatuses: formatDropdowns(action.payload),
});

const getLicenseTermsSuccess = (state, action) => ({
  ...state,
  licenseTerms: formatDropdowns(action.payload),
});

const getLicenseDesignationsSuccess = (state, action) => ({
  ...state,
  licenseDesignations: formatDropdowns(action.payload),
});

const getLicenseAuthoritiesSuccess = (state, action) => ({
  ...state,
  licenseAuthorities: action.payload,
});

const getLicenseBusinessTypesSucess = (state, action) => ({
  ...state,
  licenseBusinessTypes: action.payload,
});

const getCountiesSuccess = (state, action) => ({
  ...state,
  counties: formatCountiesDropdown(action.payload),
});

const getResultsSuccess = (state, action) => ({
  ...state,
  results: action.payload.data.map(formatRecord),
  resultsMetadata: action.payload.metadata,
});

const getDetailsSuccess = (state, action) => ({
  ...state,
  details: formatRecord(action.payload),
});

const getRetailersSuccess = (state, action) => ({
  ...state,
  retailers: action.payload.data.map(formatRecord),
});

const postUnlicensedComplaintSuccess = (state, action) => ({
  ...state,
  unlicensedComplaintResponse: action.payload,
});

const updateUnlicensedComplaint = (state, action) => ({
  ...state,
  unlicensedComplaintResponse: action.payload,
});

const getTableLoading = (state, action) => ({
  ...state,
  tableLoading: action.payload,
});

const getLocationAutoSuggestSuccess = (state, action) => ({
  ...state,
  locationAutoSuggest: action.payload.filter((location) => location.address.adminDistrict === 'California'),
});

const getLocationSuccess = (state, action) => ({
  ...state,
  searchLocationCoords: action.payload,
});

const updateUserGeolocation = (state, action) => ({
  ...state,
  userGeolocation: action.payload,
});

const updateRetailersLoading = (state, action) => ({
  ...state,
  retailersLoading: action.payload,
});

const updateBreadcrumbLocation = (state, action) => ({
  ...state,
  breadcrumbLocation: action.payload,
});

const updateUserLocationInit = (state, action) => ({
  ...state,
  userLocationInit: action.payload,
});


export default handleActions({
  [C.UPDATE_SEARCH]: updateSearch,
  [C.UPDATE_FILTERS]: updateFilters,
  [C.UPDATE_GEOLOCATION]: updateGeolocation,
  [C.GET_COUNTIES_SUCCESS]: getCountiesSuccess,
  [C.GET_LICENSE_TYPES_SUCCESS]: getLicenseTypesSuccess,
  [C.GET_LICENSE_STATUSES_SUCCESS]: getLicenseStatusesSuccess,
  [C.GET_LICENSE_TERMS_SUCCESS]: getLicenseTermsSuccess,
  [C.GET_LICENSE_DESIGNATIONS_SUCCESS]: getLicenseDesignationsSuccess,
  [C.GET_LICENSE_AUTHORITIES_SUCCESS]: getLicenseAuthoritiesSuccess,
  [C.GET_LICENSE_BUSINESS_TYPES_SUCCESS]: getLicenseBusinessTypesSucess,
  [C.GET_RESULTS_SUCCESS]: getResultsSuccess,
  [C.GET_DETAILS_SUCCESS]: getDetailsSuccess,
  [C.GET_RETAILERS_SUCCESS]: getRetailersSuccess,
  [C.POST_UNLICENSED_COMPLAINT_SUCCESS]: postUnlicensedComplaintSuccess,
  [C.UPDATE_UNLICENSED_COMPLAINT]: updateUnlicensedComplaint,
  [C.GET_TABLE_LOADING]: getTableLoading,
  [C.GET_LOCATON_AUTO_SUGGEST_SUCCESS]: getLocationAutoSuggestSuccess,
  [C.GET_LOCATON_SUCCESS]: getLocationSuccess,
  [C.UPDATE_USER_GEOLOCATION]: updateUserGeolocation,
  [C.UPDATE_RETAILERS_LOADING]: updateRetailersLoading,
  [C.UPDATE_BREADCRUMB_LOCATION]: updateBreadcrumbLocation,
  [C.UPDATE_USER_LOCATION_INIT]: updateUserLocationInit,
}, initialState);
